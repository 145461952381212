import { EmptyAction, DataAction, ErrorAction } from './utils';
import { FulfillmentLocation } from '../../models/fulfillmentLocation';

export enum ActionType {
  GET_CURRENT_FULFILLMENT_LOCATION_REQUEST = 'GET_CURRENT_FULFILLMENT_LOCATION/REQUEST',
  GET_CURRENT_FULFILLMENT_LOCATION_FAILURE = 'GET_CURRENT_FULFILLMENT_LOCATION/FAILURE',
  GET_CURRENT_FULFILLMENT_LOCATION_SUCCESS = 'GET_CURRENT_FULFILLMENT_LOCATION/SUCCESS',

  SET_CURRENT_FULFILLMENT_LOCATION_REQUEST = 'SET_CURRENT_FULFILLMENT_LOCATION/REQUEST',
  SET_CURRENT_FULFILLMENT_LOCATION_FAILURE = 'SET_CURRENT_FULFILLMENT_LOCATION/FAILURE',
  SET_CURRENT_FULFILLMENT_LOCATION_SUCCESS = 'SET_CURRENT_FULFILLMENT_LOCATION/SUCCESS',
}

export type GetCurrentFulfillmentLocationRequestAction = EmptyAction<ActionType.GET_CURRENT_FULFILLMENT_LOCATION_REQUEST>;
export type GetCurrentFulfillmentLocationSuccessAction = DataAction<ActionType.GET_CURRENT_FULFILLMENT_LOCATION_SUCCESS, FulfillmentLocation>;
export type GetCurrentFulfillmentLocationFailureAction = ErrorAction<ActionType.GET_CURRENT_FULFILLMENT_LOCATION_FAILURE>;

export type SetCurrentFulfillmentLocationRequestAction = DataAction<ActionType.SET_CURRENT_FULFILLMENT_LOCATION_REQUEST, FulfillmentLocation>;
export type SetCurrentFulfillmentLocationSuccessAction = EmptyAction<ActionType.SET_CURRENT_FULFILLMENT_LOCATION_SUCCESS>;
export type SetCurrentFulfillmentLocationFailureAction = ErrorAction<ActionType.SET_CURRENT_FULFILLMENT_LOCATION_FAILURE>;

export type GetCurrentFulfillmentLocationAction =
  GetCurrentFulfillmentLocationRequestAction |
  GetCurrentFulfillmentLocationSuccessAction |
  GetCurrentFulfillmentLocationFailureAction;
export type SetCurrentFulfillmentLocationAction =
  SetCurrentFulfillmentLocationRequestAction |
  SetCurrentFulfillmentLocationSuccessAction |
  SetCurrentFulfillmentLocationFailureAction;

export type Action =
  GetCurrentFulfillmentLocationAction | SetCurrentFulfillmentLocationAction;

export const getCurrentFulfillmentLocation = {
  request: (): GetCurrentFulfillmentLocationRequestAction => ({
    type: ActionType.GET_CURRENT_FULFILLMENT_LOCATION_REQUEST,
  }),
  failure: (error: string): GetCurrentFulfillmentLocationFailureAction => ({
    error,
    type: ActionType.GET_CURRENT_FULFILLMENT_LOCATION_FAILURE,
  }),
  success: (data: FulfillmentLocation): GetCurrentFulfillmentLocationSuccessAction => ({
    data,
    type: ActionType.GET_CURRENT_FULFILLMENT_LOCATION_SUCCESS,
  }),
};

export const setCurrentFulfillmentLocation = {
  request: (data: FulfillmentLocation): SetCurrentFulfillmentLocationRequestAction => ({
    data,
    type: ActionType.SET_CURRENT_FULFILLMENT_LOCATION_REQUEST,
  }),
  failure: (error: string): SetCurrentFulfillmentLocationFailureAction => ({
    error,
    type: ActionType.SET_CURRENT_FULFILLMENT_LOCATION_FAILURE,
  }),
  success: (): SetCurrentFulfillmentLocationSuccessAction => ({
    type: ActionType.SET_CURRENT_FULFILLMENT_LOCATION_SUCCESS,
  }),
};
