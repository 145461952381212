import { EmptyAction, DataAction, ErrorAction } from './utils';
import { LoadingRestriction } from '../../models/loading-restriction';

export enum ActionType {
  GET_LOADING_RESTRICTIONS_REQUEST = 'GET_LOADING_RESTRICTIONS/REQUEST',
  GET_LOADING_RESTRICTIONS_FAILURE = 'GET_LOADING_RESTRICTIONS/FAILURE',
  GET_LOADING_RESTRICTIONS_SUCCESS = 'GET_LOADING_RESTRICTIONS/SUCCESS',
}

export type GetLoadingRestrictionsRequestAction =
  EmptyAction<ActionType.GET_LOADING_RESTRICTIONS_REQUEST>;
export type GetLoadingRestrictionsSuccessAction =
  DataAction<ActionType.GET_LOADING_RESTRICTIONS_SUCCESS, LoadingRestriction[]>;
export type GetLoadingRestrictionsFailureAction =
  ErrorAction<ActionType.GET_LOADING_RESTRICTIONS_FAILURE>;

export type GetLoadingRestrictionsAction =
  GetLoadingRestrictionsRequestAction |
  GetLoadingRestrictionsSuccessAction |
  GetLoadingRestrictionsFailureAction;

export type Action =
  GetLoadingRestrictionsAction;

export const getLoadingRestrictions = {
  request: (): GetLoadingRestrictionsRequestAction => ({
    type: ActionType.GET_LOADING_RESTRICTIONS_REQUEST,
  }),
  failure: (error: string): GetLoadingRestrictionsFailureAction => ({
    error,
    type: ActionType.GET_LOADING_RESTRICTIONS_FAILURE,
  }),
  success: (data: LoadingRestriction[]): GetLoadingRestrictionsSuccessAction => ({
    data,
    type: ActionType.GET_LOADING_RESTRICTIONS_SUCCESS,
  }),
};
