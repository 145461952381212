import './cimpress-colors.css';
import './index.css';
import React from 'react';
import { render } from 'react-dom';
import { configureStore, createSerializableStateInvariantMiddleware } from 'redux-starter-kit';
import App from './App';
import { Provider } from 'react-redux';
import { combineReducers, Middleware, Dispatch } from 'redux';
import apiStatusReducer from './common/apiStatus/apiStatusReducer';
import authReducer from './store/reducers/auth.reducer';

import errorHandler from './utils/error-handler';
import locationReducer from './store/reducers/location.reducer';
import fulfillmentLocationReducer from './store/reducers/fulfillment-location.reducer';
import { createNovelsMiddleware } from './store/middlewares/novels';
import { createAppNovels, addAppNovels } from './store/novels';
import { StoreState } from './common/commonModels';

import { Action } from './store/actions';

import { toggleFeature, ActionType } from './store/actions/feature';
import { createPersistMiddleware } from './store/middlewares/persist';
import persistenceReducer from './store/reducers/persistence.reducer';
import { composeReducers } from './store/reducers';
import { restore } from './store/actions/persistence';
import { createAppServices } from './app.services';
import { LoggingProvider } from './contexts/logging-provider';

const reducer = {
  apiCallsInProgress: apiStatusReducer,
  user: authReducer,
  location: locationReducer,
  fulfillmentLocation: fulfillmentLocationReducer,
  
} as any;

const novelsMiddleware = createNovelsMiddleware<StoreState, Action>(
  (err, action, novel) => {
    console.warn(
      `Error '${err.message}' raised by novel '${novel.name}', triggered on action '${action.type}'`,
    );
    throw err;
  },
);

const persistenceMiddleware = createPersistMiddleware<StoreState>(
  {
    set: (item: Partial<StoreState>) => localStorage.setItem('features-flags', JSON.stringify(item)),
    get: (): Partial<StoreState> => JSON.parse(localStorage.getItem('features-flags') || '{}'),
  },
  ActionType.TOGGLE_FEATURE,
  'features',
);

let middleware: Middleware<{}, StoreState, Dispatch<Action>>[] = [
  novelsMiddleware,
  persistenceMiddleware,
];

if (process.env.NODE_ENV !== 'production') {
  const createImmutableStateInvariantMiddleware = require('redux-immutable-state-invariant')
    .default;

  middleware = [
    createImmutableStateInvariantMiddleware(),
    ...middleware,
    createSerializableStateInvariantMiddleware(),
  ];
}

(window as any).toggleFeature = (feature: string) => {
  store.dispatch(toggleFeature(feature));
};

const store = configureStore<StoreState, Action>({
  middleware,
  reducer: composeReducers(
    combineReducers(reducer),
    persistenceReducer,
  ),
});

const appServices = createAppServices(store);
const appNovels = createAppNovels(appServices);

window.addEventListener('error', errorHandler(appServices.loggingService));

addAppNovels(novelsMiddleware, appNovels);

store.dispatch(restore());

render(
  <LoggingProvider service={appServices.loggingService}>
    <Provider store={store}>
      <App />
    </Provider>
  </LoggingProvider>,
  document.getElementById('root'),
);
