import { Profile } from '../models/auth';

export function fullStoryIdentify(profile: Profile) {
  const fullStory = (window as any).FS;
  if (!fullStory) {
    return;
  }

  fullStory.identify(profile.sub, {
    displayName: profile.name,
    email: profile.email,
  });
}
