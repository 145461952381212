import AuthService from '../../ports/AuthService';
import { AuthenticateRequestAction, authenticate, LogOutRequestAction, logOut } from '../actions/auth';
import { Novel } from '../middlewares/novels';
import { Action } from '../actions';

export interface AuthNovels {
  authenticateNovel: Novel<AuthenticateRequestAction, Action>;
  logOutNovel: Novel<LogOutRequestAction, Action>;
}

export const authNovelsCreator = (
  authService: AuthService,
) => {
  const authenticateNovel = async (
    { data }: AuthenticateRequestAction,
    dispatch: (action: Action) => void,
  ) => {
    try {
      const isLoggedIn = await authService.ensureAuthentication(data);

      if (!isLoggedIn) {
        dispatch(authenticate.failure('Authentication failed.'));
      }

      dispatch(authenticate.success(authService.getProfile()));
    } catch (err) {
      dispatch(authenticate.failure(err.message));
    }
  };

  const logOutNovel = async (
    {}: LogOutRequestAction,
    dispatch: (action: Action) => void,
  ) => {
    try {
      await authService.logout();

      dispatch(logOut.success());
    } catch (err) {
      dispatch(logOut.failure(err.message));
    }
  };

  return {
    authenticateNovel,
    logOutNovel,
  };
};
