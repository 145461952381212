import { DataAction, EmptyAction, ErrorAction } from './utils';
import { Dictionary } from '../../models/utils';

export enum ActionType {
  GET_FEATURE_FLAGS_REQUEST = 'GET_FEATURE_FLAGS/REQUEST',
  GET_FEATURE_FLAGS_FAILURE = 'GET_FEATURE_FLAGS/FAILURE',
  GET_FEATURE_FLAGS_SUCCESS = 'GET_FEATURE_FLAGS/SUCCESS',

  TOGGLE_FEATURE = 'TOGGLE_FEATURE',
}

export type GetFeatureFlagsRequestAction = EmptyAction<ActionType.GET_FEATURE_FLAGS_REQUEST>;
export type GetFeatureFlagsSuccessAction = DataAction<ActionType.GET_FEATURE_FLAGS_SUCCESS, Dictionary<boolean>>;
export type GetFeatureFlagsFailureAction = ErrorAction<ActionType.GET_FEATURE_FLAGS_FAILURE>;

export type GetFeatureFlagsAction =
  GetFeatureFlagsRequestAction |
  GetFeatureFlagsSuccessAction |
  GetFeatureFlagsFailureAction;

export type ToggleFeatureAction = DataAction<ActionType.TOGGLE_FEATURE, string>;

export type Action =
  GetFeatureFlagsAction |
  ToggleFeatureAction;

export const getFeatureFlags = {
  request: (): GetFeatureFlagsRequestAction => ({
    type: ActionType.GET_FEATURE_FLAGS_REQUEST,
  }),
  success: (data: Dictionary<boolean>): GetFeatureFlagsSuccessAction => ({
    data,
    type: ActionType.GET_FEATURE_FLAGS_SUCCESS,
  }),
  failure: (error: string): GetFeatureFlagsFailureAction => ({
    error,
    type: ActionType.GET_FEATURE_FLAGS_FAILURE,
  }),
};

export const toggleFeature = (data: string): ToggleFeatureAction => ({
  data,
  type: ActionType.TOGGLE_FEATURE,
});
