import { centralizedAuth as CentralizedAuth } from '@cimpress/simple-auth-wrapper';
import { Profile } from '../models/auth';

export const CLIENT_ID = '0fpkluGMXts5jkvXd5I1prfdcNb4WFhF';

const auth = new CentralizedAuth({
  clientID: CLIENT_ID,
  redirectRoute: '/',
});

const location = window.location;
auth.on('tokenExpired', () => auth.login({
  nextUri: `${location.pathname}${location.search}${location.hash}`,
  forceLogin: true,
}));

const getAccessToken = (): string => auth.getAccessToken();

export default {
  ensureAuthentication: async (nextUri: string) => await auth.ensureAuthentication({ nextUri }) as boolean,
  getProfile: (): Profile => {
    const rawProfile = auth.getProfile();

    const profile: Profile = {
      email: rawProfile.email,
      name: rawProfile.name,
      sub: rawProfile.sub,
      picture: rawProfile.picture,
      domain: rawProfile.domain,
    };
    return profile;
  },
  isLoggedIn: (): boolean => auth.isLoggedIn(),
  login: async () => await auth.login() as boolean,
  logout: (): void => auth.logout('/', false),
  getAuthHeader: (): string => `Bearer ${getAccessToken()}`,
};
