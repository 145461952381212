import { Reducer } from 'redux';
import { StoreState } from '../../common/commonModels';
import { Action } from '../actions';
import { ActionType } from '../actions/persistence';

const persistenceReducer: Reducer<StoreState, Action> = (state, action) => {
  switch (action.type) {
    case ActionType.RESTORE:
      return {
        ...state!,
        ...action.data,
      };
    default:
      return state!;
  }
};

export default persistenceReducer;
