import React from "react"
import { Link } from 'react-router-dom';
import { Label } from '@cimpress/react-components';
import { Card } from '@cimpress/react-components';


const info = (props) => {
    const message = `we will send you an email on ${props.location.state.email} with the links to download necessary files`
    const email = 'In case of any Query please contact mailto:Logistics Support <logisticssupport@cimpress.com>'
    return (
        <div>
            <div className="row" style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <div className="card-deck">
                <Card header='Success'>
                    <p className="card-text">Your request has been accepted and soon will be processed</p>
                    <p className="card-text">You will receive an email on <strong>{props.location.state.email} </strong> with the links to download necessary files</p>
                    <p> In case of any query or you do not receive an email in <strong>24 hours </strong> please contact <a href="mailto:logisticssupport@cimpress.com">logisticssupport@cimpress.com</a></p>
                </Card>
                {/* <div>
                    <Link to="/" >Back</Link>
                </div> */}
            </div>
           
            
            </div>
            <div className="lll" style={{
                position: 'absolute',
                           
                width: '20%',
                left: '22%',
                top: '220px',
                height: '7em'
                
            }}>
                <br/>
                              
                                                    <Link to="/" >Back</Link>
            </div>

        </div>


    )
}

export default info