import React from "react";
import axios from "axios";
import postalCodes from "postal-codes-js";
import createBrowserHistory from "history/createBrowserHistory";
import { Redirect } from "react-router-dom";
import { Select } from "@cimpress/react-components";
import { Button } from "@cimpress/react-components";
import { Alert, Card } from "@cimpress/react-components";
import { Label } from "@cimpress/react-components";
import { shapes } from "@cimpress/react-components";
import { getFulfillmentLocations } from "../../fulfillment-locations/fulfillmentLocationsActions";
import { nameOf } from "../../common/utils";
import authApi from "../../auth/authApi";
import countries from "./country-list.json";
import { Checkbox } from "@cimpress/react-components";
import { TextField } from "@cimpress/react-components";

const upsServices = [
  //should be changed
  { label: "UPS Ground", value: "UPS Ground" },
  { label: "UPS Express", value: "UPS Express" },
  { label: "UPS Expedited", value: "UPS Expedited" },
  { label: "UPS Next day air Saver", value: "UPS Next Day Air Saver" },
  { label: "UPS Express Saver", value: "UPS Express Saver" },
  { label: "UPS 2nd Day", value: "UPS 2nd Day Air" },
  { label: "UPS Standard", value: "UPS Standard" },
  { label: "UPS 3 Day Select", value: "UPS 3 Day Select" },
];

const dhlServices = [
  { label: "DHL Express", value: "recs:dhl-express" },
  { label: "DHL Economy Select", value: "recs:dhl-economy-select" },
];

const fedExServices = [
  { label: "FedEx Ground", value: "FedEx Ground" },
  { label: "FedEx Priority Overnight", value: "FedEx Priority Overnight" },
  { label: "FedEx 2Day", value: "FedEx 2Day" },
  { label: "FedEx Standard Overnight", value: "FedEx Standard Overnight" },
  { label: "FedEx First Overnight", value: "FedEx First Overnight" },
  { label: "FedEx Express Saver", value: "FedEx Express Saver" },
  { label: "FedEx 2Day AM", value: "FedEx 2Day AM" },
  { label: "FedEx Home Delivery", value: "FedEx Home Delivery" },
];

const carriers = [
  { label: "DHL Express", value: "DHL Express" },
  { label: "UPS", value: "UPS" },
  { label: "TNT Italy", value: "TNT-IT" },
  { label: "FedEx", value: "FedEx" },
];

const browserHistory = createBrowserHistory();

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFulfillmentLocation: null,
      fulfillmentLocations: countries,
      submited: false,
      showSpinner: false,
      services: null,
      showAlert: false,
      errors: [],
    };
  }

  selectedValues = async () => {
    const authorization = authApi.getAuthHeader();

    axios.defaults.headers.common["Authorization"] = authorization;

    const errors = [];
    if (!this.state.selectedFulfillmentLocation) {
      errors.push(
        "Fulfillment Location is the required field, please select one\n"
      );
    }
    if (!this.state.selectedSingle) {
      errors.push("Carrier is the required field, please select one\n");
    }
    if (!this.state.destinationCountry) {
      errors.push("A destination country should be selected\n");
    }

    if (errors.length > 0) {
      this.setState({
        showAlert: true,
        errors: errors,
      });
      return;
    }

    const body = {
      fulfillmentLocation: this.state.selectedFulfillmentLocation,
      fromLocationId:
        this.state.selectedFulfillmentLocation.fulfillmentLocationId, // this will be removed in future
      carrierName: this.state.selectedSingle.value,
      toCountryCode: [this.state.destinationCountry.value],
      emailAddress: this.props.user.email,
      carrierServices: this.state.selectedMultiServices.map(
        (service) => service.value
      ),
      usePickupLocation: this.state.pickuplocation,
      pickupLocation: {
        fromCountryCode:
          this.state.fromCountryCode && this.state.fromCountryCode.value,
        fromPostalCode: this.state.fromPostalCode,
      },
    };
    this.setState({
      showSpinner: true,
    });

    const response = await axios.post("/api/v1/generateTransitQuotes", body);
    console.log("the response of post ", response);

    if (response.status === 200) {
      this.setState({
        submited: true,
        showSpinner: false,
      });
    }
  };

  async componentDidMount() {
    const authorization = authApi.getAuthHeader();
    axios.defaults.headers.common["Authorization"] = authorization;
    const response = await axios.get("/api/v1/locations/fulfillmentlocations");
    const fulfillmentLocations = response.data
      .map((fulfillmentLocation) => ({
        ...fulfillmentLocation,
        label: `${fulfillmentLocation.name}  -   ${fulfillmentLocation.fulfillmentLocationId}`,
      }))
      .filter(
        ({ dispatchAddressPostalCode, dispatchAddressCountry }) =>
          postalCodes.validate(dispatchAddressCountry, dispatchAddressPostalCode) ===
          true
      );

    let passedFulfillmentLocation;
    if (this.props.match.params.fulfilmentLocationId !== undefined) {
      passedFulfillmentLocation = fulfillmentLocations.find(
        (fulfillmentLocation) => {
          if (
            fulfillmentLocation.fulfillmentLocationId ===
            this.props.match.params.fulfilmentLocationId
          ) {
            return fulfillmentLocation;
          }
        }
      );
    }

    if (passedFulfillmentLocation) {
      this.setState({
        selectedFulfillmentLocation: passedFulfillmentLocation,
      });

      browserHistory.push(
        `/${passedFulfillmentLocation.fulfillmentLocationId}`
      );
    }

    this.setState({
      fulfillmentLocations,
      selectedMultiServices: [],
      pickuplocation: false,
    });
  }

  onDestinationCountryChange = (destinationCountry) => {
    this.setState({ destinationCountry });
  };

  onMultiSelectionServicesChange = (selectedMultiServices) => {
    this.setState({ selectedMultiServices });
  };

  onSingleSelectionChange = (selectedSingle) => {
    console.log("selectedSingle.value ", selectedSingle.value);
    console.log("this.state.selectedSingle ", this.state.selectedSingle);
    if (
      this.state.selectedSingle &&
      selectedSingle.value !== this.state.selectedSingle.value
    ) {
      this.setState({
        selectedMultiServices: [],
      });
    }
    if (selectedSingle.value === "UPS") {
      this.setState({ services: upsServices });
    } else if (selectedSingle.value === "DHL Express") {
      this.setState({ services: dhlServices });
    } else if (selectedSingle.value === "FedEx") {
      this.setState({ services: fedExServices });
    } else {
      this.setState({ services: [] });
    }
    this.setState({ selectedSingle });
  };

  formatErrors = () => {
    let formatedErrors = "";
    let i = 1;
    this.state.errors.forEach((error) => {
      formatedErrors += `<p> ${i}. ${error}</p></br>`;
      i++;
    });

    return formatedErrors;
  };

  // tslint:disable-next-line:variable-label
  render() {
    const { Spinner } = shapes;

    return (
      <div>
        {this.state.showAlert ? (
          <Alert
            title="Validation Alert"
            message={this.formatErrors()}
            dismissible={true}
            onDismiss={() =>
              this.setState({
                showAlert: false,
                errors: [],
              })
            }
          />
        ) : (
          ""
        )}
        <div className="page-title">
          <div className="h3">
            Please Select proper options to generate transit files.
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-center">
            {this.state.showSpinner ? <Spinner /> : ""}
          </div>

          <div>
            {/* <Label text='All the below fields are required' type="info" />
            <br />
            <br /> */}
            <Select
              isClearable
              label="Select a fulfillment location"
              value={this.state.selectedFulfillmentLocation}
              options={this.state.fulfillmentLocations}
              onChange={(option) => {
                if (option) {
                  this.setState({
                    selectedFulfillmentLocation: option,
                  });
                  console.log(
                    "Pushing router to ",
                    option.fulfillmentLocationId
                  );
                  // browserHistory.push(`/estimate-planning/${option.fulfillmentLocationId}`)
                  browserHistory.push(`/${option.fulfillmentLocationId}`);
                }
              }}
              tether
              helpText="Please select Fulfillment location. (REQUIRED)"
            />
            <div>
              <Checkbox
                label={"Use Pickup location"}
                checked={this.state.pickuplocation}
                // payload="indeterminate"
                onChange={() =>
                  this.setState({ pickuplocation: !this.state.pickuplocation })
                }
              />
              <div className="ml-5">
                {this.state.pickuplocation && (
                  <TextField
                    name="fromPostalCode"
                    label="Please enter origin postal code"
                    value={this.state.fromPostalCode}
                    onChange={(e) =>
                      this.setState({ fromPostalCode: e.target.value })
                    }
                  />
                )}

                {this.state.pickuplocation && (
                  <Select
                    isClearable
                    label="Select Pickup Country"
                    value={this.state.fromCountryCode}
                    options={countries}
                    onChange={(countryCode) =>
                      this.setState({ fromCountryCode: countryCode })
                    }
                    helpText="Please select one country. (REQUIRED)"
                  />
                )}
              </div>
            </div>
            <Select
              isClearable
              label="Select destination Country"
              value={this.state.destinationCountry}
              options={countries}
              onChange={this.onDestinationCountryChange}
              helpText="Please select one country. (REQUIRED)"
            />
            <Select
              isClearable
              label="Select the Carrier"
              value={this.state.selectedSingle}
              options={carriers}
              onChange={this.onSingleSelectionChange}
              tether
              helpText="Please select carrier. (REQUIRED)"
            />
            <Select
              isClearable
              label="Select Carrier Services"
              value={this.state.selectedMultiServices}
              options={this.state.services}
              onChange={this.onMultiSelectionServicesChange}
              isMulti
              helpText="Please select zero or more carrier services. (OPTIONAL)"
            />
            <br />
            <Button onClick={this.selectedValues}>Submit</Button>
            <br />
            <br />
            {/* <div className="card-deck">
                <Card header='Info'>
                    
                    <p className="card-text">On Completion of process email will be sent to  <strong> {this.props.user.email}</strong></p>
                    <p className="card-text">If you do not recive email in 24 hours please contact <a href="mailto:logisticssupport@cimpress.com">logisticssupport@cimpress.com</a></p>
                    
                </Card>
                
            </div> */}

            {/* <MultiSelectCombo id='countries' selectionLimit={-1} data={countries} placeHolder="Select Countries" singleSelect={false} myRef={this.multiselectRefCountries}></MultiSelectCombo>
            <MultiSelectCombo id='carriers' selectionLimit={1} data={carriers} placeHolder="Select the Carrier" singleSelect={true} myRef={this.multiselectRefCarriers}></MultiSelectCombo> */}
            {/* <Label text={this.message} type="info" /> */}
            {/* <input type="text" name="email" readOnly={true} id="email" placeholder="Enter your Email Address" value={this.props.user.email} /> */}
            {/* <br></br>
            <br />
            <Button onClick={this.selectedValues} >Submit</Button> */}

            {this.state.submited ? (
              <Redirect
                to={{
                  pathname: "/info",
                  state: { email: this.props.user.email },
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;
