import { Dictionary } from '../../models/utils';
import { Action } from '../actions';
import { Reducer } from 'redux';
import { ActionType } from '../actions/feature';

const featureReducer: Reducer<Dictionary<boolean>, Action> = (state = {}, action) => {
  switch (action.type) {
    case ActionType.TOGGLE_FEATURE:
      return {
        ...state,
        [action.data]: !state[action.data],
      };

    case ActionType.GET_FEATURE_FLAGS_SUCCESS:
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
};

export default featureReducer;
