import { Reducer } from 'redux';
import { Action } from '../actions';
import { ActionType } from '../actions/location';
import { Location } from '../../models/location';
import { LocationKey } from '../../models/utils';

export const nullLocation: Location = {
  key: 'null' as LocationKey,
  name: 'Loading ...',
  localeSettings: {
    dateFormat: 'YYYY-MM-DD',
    timezone: 'Etc/UTC',
  },
};

const locationReducer: Reducer<Location, Action> = (state = nullLocation as Location, action) => {
  switch (action.type) {
    case ActionType.GET_CURRENT_LOCATION_REQUEST:
    case ActionType.GET_CURRENT_LOCATION_FAILURE:
      return state;
    case ActionType.GET_CURRENT_LOCATION_SUCCESS:
      return action.data;
    default:
      return state;
  }
};

export default locationReducer;
