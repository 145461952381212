import { Reducer } from 'redux';
import { Action } from '../actions';
import { ActionType } from '../actions/customs-broker';
import { CustomsBrokerState } from '../state/customs-broker';
import { CustomsBroker } from '../../models/customs-broker';
import { Dictionary } from '../../models/utils';

const defaultState: CustomsBrokerState = {
  all: {},
  isLoading: false,
};

const customsBrokerReducer: Reducer<CustomsBrokerState, Action> = (state = defaultState, action) => {
  switch (action.type) {
    case ActionType.GET_CUSTOMS_BROKERS_REQUEST:
      return {
        ...state, isLoading: true,
      };
    case ActionType.GET_CUSTOMS_BROKERS_FAILURE:
      return {
        ...state, isLoading: false,
      };
    case ActionType.GET_CUSTOMS_BROKERS_SUCCESS:
      const all: Dictionary<CustomsBroker> = {};
      action.data.forEach(cb => all[cb.id] = cb);
      return {
        all,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default customsBrokerReducer;
