import { EmptyAction, DataAction, ErrorAction } from './utils';
import { Location } from '../../models/location';

export enum ActionType {
  GET_CURRENT_LOCATION_REQUEST = 'GET_CURRENT_LOCATION/REQUEST',
  GET_CURRENT_LOCATION_FAILURE = 'GET_CURRENT_LOCATION/FAILURE',
  GET_CURRENT_LOCATION_SUCCESS = 'GET_CURRENT_LOCATION/SUCCESS',

  SET_CURRENT_LOCATION_REQUEST = 'SET_CURRENT_LOCATION/REQUEST',
  SET_CURRENT_LOCATION_FAILURE = 'SET_CURRENT_LOCATION/FAILURE',
  SET_CURRENT_LOCATION_SUCCESS = 'SET_CURRENT_LOCATION/SUCCESS',
}

export type GetCurrentLocationRequestAction = EmptyAction<ActionType.GET_CURRENT_LOCATION_REQUEST>;
export type GetCurrentLocationSuccessAction = DataAction<ActionType.GET_CURRENT_LOCATION_SUCCESS, Location>;
export type GetCurrentLocationFailureAction = ErrorAction<ActionType.GET_CURRENT_LOCATION_FAILURE>;

export type SetCurrentLocationRequestAction = DataAction<ActionType.SET_CURRENT_LOCATION_REQUEST, Location>;
export type SetCurrentLocationSuccessAction = EmptyAction<ActionType.SET_CURRENT_LOCATION_SUCCESS>;
export type SetCurrentLocationFailureAction = ErrorAction<ActionType.SET_CURRENT_LOCATION_FAILURE>;

export type GetCurrentLocationAction =
  GetCurrentLocationRequestAction |
  GetCurrentLocationSuccessAction |
  GetCurrentLocationFailureAction;
export type SetCurrentLocationAction =
  SetCurrentLocationRequestAction |
  SetCurrentLocationSuccessAction |
  SetCurrentLocationFailureAction;

export type Action =
  GetCurrentLocationAction | SetCurrentLocationAction;

export const getCurrentLocation = {
  request: (): GetCurrentLocationRequestAction => ({
    type: ActionType.GET_CURRENT_LOCATION_REQUEST,
  }),
  failure: (error: string): GetCurrentLocationFailureAction => ({
    error,
    type: ActionType.GET_CURRENT_LOCATION_FAILURE,
  }),
  success: (data: Location): GetCurrentLocationSuccessAction => ({
    data,
    type: ActionType.GET_CURRENT_LOCATION_SUCCESS,
  }),
};

export const setCurrentLocation = {
  request: (data: Location): SetCurrentLocationRequestAction => ({
    data,
    type: ActionType.SET_CURRENT_LOCATION_REQUEST,
  }),
  failure: (error: string): SetCurrentLocationFailureAction => ({
    error,
    type: ActionType.SET_CURRENT_LOCATION_FAILURE,
  }),
  success: (): SetCurrentLocationSuccessAction => ({
    type: ActionType.SET_CURRENT_LOCATION_SUCCESS,
  }),
};
