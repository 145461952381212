import { Reducer } from 'redux';
import { Action } from '../actions';
import { ActionType } from '../actions/linehaul-company';
import { LinehaulCompany } from '../../models/linehaul-company';
import { Dictionary } from '../../models/utils';
import { LinehaulCompanyState } from '../state/linehaul-company';

const defaultState: LinehaulCompanyState = {
  all: {},
  isLoading: false,
};

const linehaulCompanyReducer: Reducer<LinehaulCompanyState, Action> = (state = defaultState, action) => {
  switch (action.type) {
    case ActionType.GET_LINEHAUL_COMPANIES_REQUEST:
      return {
        ...state, isLoading: true,
      };
    case ActionType.GET_LINEHAUL_COMPANIES_FAILURE:
      return {
        ...state, isLoading: false,
      };
    case ActionType.GET_LINEHAUL_COMPANIES_SUCCESS:
      const all: Dictionary<LinehaulCompany> = {};
      action.data.forEach(linehaulCompany => all[linehaulCompany.id] = linehaulCompany);
      return {
        all,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default linehaulCompanyReducer;
