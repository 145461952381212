import React from 'react';
import { Toast } from '../../../models/utils';
import { Transition } from 'react-transition-group';
import CopyComponent from '../CopyComponent';

import './ToastComponent.css';

// tslint:disable-next-line:variable-name
const ToastComponent = ({
  dismiss,
  toast: {
    type,
    message,
    details,
    correlationId,
    links,
  },
}: { toast: Toast; dismiss: () => void }) => {
  const [showMore, setShowMore] = React.useState(false);
  const [dismissed, setDismissed] = React.useState(false);

  const onShowMore = (event: React.MouseEvent) => {
    event.preventDefault();
    setShowMore(true);
  };

  return (
    <Transition
      in={!dismissed}
      timeout={350}
      appear
      enter={true}
      exit={true}
      onEnter={ref => ref.style.transform = 'translateX(525px)'}
      onEntered={ref => ref.style.transform = ''}
      onExit={ref => ref.style.height = `${ref.scrollHeight}px`}
      onExiting={ref => ref.classList.add('dismissed')}
      onExited={() => dismiss()}
    >
      <div className={`ToastComponent alert alert-${type} alert-dismissible`}>
          <div>
          <button type="button" className="close" style={{ outline: 'none' }} onClick={() => setDismissed(true)}>×</button>
          <div>
            <h5>{message}</h5>
            {
              showMore
                ? <div className="more">
                    <h6>Message</h6>
                    <p>{details}</p>
                    {correlationId && <h6>Correlation id</h6>}
                    {correlationId &&
                      <p>
                        {correlationId}
                        <CopyComponent
                          value={correlationId}
                          title="Copy the correlation id"
                        />
                      </p>}
                  </div>
                : <p><a href="#" onClick={onShowMore}>Show more</a></p>
            }
            {
              links.map((link, i) => <a href={link.href} key={i}>{link.label}</a>)
            }
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default ToastComponent;
