import { combineReducers, Reducer } from 'redux';
import { StoreState } from '../../common/commonModels';

import { default as apiCallStatusReducer } from '../../common/apiStatus/apiStatusReducer';
import authReducer from './auth.reducer';
import shipmentGroupReducer from './shipment-group.reducer';
import truckReducer from './truck.reducer';
import linehaulCompanyReducer from './linehaul-company.reducer';
import customsBrokerReducer from './customs-broker.reducer';
import loadingRestrictionReducer from './loading-restriction.reducer';
import locationReducer from './location.reducer';
import fulfillmentLocationReducer from './fulfillment-location.reducer';
import truckTemplateReducer from './truck-template.reducer';
import toastReducer from './toast.reducer';
import uiOptionsReducer from './ui-options.reducer';
import manifestEventLogReducer from './manifest-event-log.reducer';
import { Action } from '../actions';
import featureReducer from './feature.reducer';
import persistenceReducer from './persistence.reducer';

export function composeReducers(...reducers: Reducer<StoreState, Action>[]): Reducer<StoreState, Action> {
  return (state, action) => {
    if (reducers.length) {
      return composeReducers(...reducers.slice(0, -1))(reducers.slice(-1)[0](state, action), action);
    }
    return state!;
  };
}

export const storeReducer = composeReducers(
  combineReducers<StoreState>({
    apiCallsInProgress: apiCallStatusReducer,
    user: authReducer,
    location: locationReducer,
    fulfillmentLocation: fulfillmentLocationReducer,
    shipmentGroups: shipmentGroupReducer,
    trucks: truckReducer,
    uiOptions: uiOptionsReducer,
    linehaulCompanies: linehaulCompanyReducer,
    customsBrokers: customsBrokerReducer,
    loadingRestrictions: loadingRestrictionReducer,
    manifestEventLogs: manifestEventLogReducer,
    truckTemplate: truckTemplateReducer,
    toasts: toastReducer,
    features: featureReducer,
  }),
  persistenceReducer,
);
