import { Reducer } from 'redux';
import { Action } from '../actions';
import { ActionType } from '../actions/shipment-group';
import { ActionType as TruckActionType } from '../actions/truck';
import { ShipmentGroupState } from '../state/shipment-group';
import { ShipmentGroup } from '../../models/shipment-group';
import { Dictionary } from '../../models/utils';

const defaultState: ShipmentGroupState = {
  all: {},
  isLoading: false,
};

const shipmentGroupReducer: Reducer<ShipmentGroupState, Action> = (state = defaultState, action) => {
  switch (action.type) {
    case ActionType.GET_SHIPMENT_GROUPS_REQUEST:
      return {
        ...state, isLoading: true,
      };

    case ActionType.GET_SHIPMENT_GROUPS_FAILURE:
      return {
        ...state, isLoading: false,
      };

    case ActionType.GET_SHIPMENT_GROUPS_SUCCESS:
      const { data } = action;
      const all: Dictionary<ShipmentGroup> = {};
      data.forEach(sg => all[sg.id] = sg);
      return {
        all,
        isLoading: false,
      };

    case TruckActionType.LOAD_TRUCK_SUCCESS:
      return {
        ...state,
        all: {
          ...state.all,
          [action.data.shipmentGroup.id]: {
            ...state.all[action.data.shipmentGroup.id],
            shipmentCount: 0,
          },
        },
      };

    default:
      return state;
  }
};

export default shipmentGroupReducer;
