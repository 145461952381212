export interface LocaleSettings {
    timezone: string;
    dateFormat: string;
  }
  
  export interface FulfillmentLocation {
    fulfillmentLocationId: string;
    name: string;
    fulfillerId: string
    dispatchAddressCountry: string,
    dispatchAddressPostalCode: string, 
    
    displayName: string
  }
  
   
  export interface FulfillmentLocationsState {
    isFetching: boolean;
    error?: Object;
    all?: FulfillmentLocation[];
    selectedFulfillmentLocationId: string;
    current: FulfillmentLocation;
  }


  export const nullFulfillmentLocation: FulfillmentLocation = {
    fulfillmentLocationId: 'null',
    name: 'Loading ...',
    fulfillerId: '',
    dispatchAddressCountry: '',
    dispatchAddressPostalCode: '', 
    displayName: '',
    
  };
  
  export const notFoundLocation: FulfillmentLocation = {
    fulfillmentLocationId: 'not found',
    name: 'not found',
    fulfillerId: '',
    dispatchAddressCountry: '',
    dispatchAddressPostalCode: '', 
    displayName: '',
  };
  