import { apiCall } from '../actions/api-call';
import { Action } from '../actions';
import { Novel } from '../middlewares/novels';
import { GetCurrentFulfillmentLocationRequestAction, getCurrentFulfillmentLocation, setCurrentFulfillmentLocation, SetCurrentFulfillmentLocationRequestAction } from '../actions/fulfillmentLocation';
import FulfillmentLocationService from '../../ports/FulfillmentLocationService';
import PersistenceService from '../../ports/PersistenceService';
import { FulfillmentLocationKey } from '../../models/utils';
import { FulfillmentLocation } from '../../models/fulfillmentLocation';

export const CURRENT_FULFILLMENT_LOCATION_KEY = 'current-fulfillment-location-key';

export const notFoundLocation: FulfillmentLocation = {
  fulfillmentLocationId: 'null' as FulfillmentLocationKey,
  name: 'Loading ...',
  fulfillerId: '',
  dispatchAddressCountry: '',
  dispatchAddressPostalCode: '',
  displayName: ''
};

export interface FulfillmentLocationNovels {
  setCurrentFulfillmentLocationNovel: Novel<SetCurrentFulfillmentLocationRequestAction, Action>;
  getCurrentFulfillmentLocationNovel: Novel<GetCurrentFulfillmentLocationRequestAction, Action>;
}

export const fulfillmentLocationNovelsCreator = (
  fulfillmentLocationService: FulfillmentLocationService,
  persistenceService: PersistenceService,
): FulfillmentLocationNovels => {
  const setCurrentFulfillmentLocationNovel = (
    { data }: SetCurrentFulfillmentLocationRequestAction,
    dispatch: (action: Action) => void,
  ) => {
    // debugger;
    console.log('setCurrentFulfillmentLocationNovel ', data)
    try {
      persistenceService.setData(
        CURRENT_FULFILLMENT_LOCATION_KEY,
        data.fulfillmentLocationId,
      );
      dispatch(setCurrentFulfillmentLocation.success());
    } catch ({ message }) {
      dispatch(setCurrentFulfillmentLocation.failure(message));
    }
  };

  const getCurrentFulfillmentLocationNovel = async (
    {}: GetCurrentFulfillmentLocationRequestAction,
    dispatch: (action: Action) => void,
  ) => {
    try {
      console.log('getCurrentFulfillmentLocationNovel ')
      debugger;
      let fulfillmentLocations: FulfillmentLocation[];
      try {
        dispatch(apiCall());
        fulfillmentLocations = await fulfillmentLocationService.getFulfillmentLocations();
        
        dispatch(apiCall.success());
      } catch (err) {
        dispatch(apiCall.failure(err.message));
        throw err;
      }
      const currentLocationKey = persistenceService.getData(CURRENT_FULFILLMENT_LOCATION_KEY);
      const currentLocation =
      fulfillmentLocations.find(fulfillmentLocation => fulfillmentLocation.fulfillmentLocationId === currentLocationKey) ||
        (fulfillmentLocations.length ? fulfillmentLocations[0] : notFoundLocation);

      persistenceService.setData(CURRENT_FULFILLMENT_LOCATION_KEY, currentLocation.fulfillmentLocationId);
      dispatch(getCurrentFulfillmentLocation.success(currentLocation));
    } catch ({ message }) {
      dispatch(getCurrentFulfillmentLocation.failure(message));
    }
  };

  return {
    getCurrentFulfillmentLocationNovel,
    setCurrentFulfillmentLocationNovel,
  };
};
