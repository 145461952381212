import { NovelsMiddleware } from '../middlewares/novels';
import { Action } from '../actions';
import { StoreState } from '../../common/commonModels';

import { ActionType as LocationActionType } from '../actions/location';
 import { ActionType as FulfillmentLocationActionType } from '../actions/fulfillmentLocation';

import { ActionType as AuthActionType } from '../actions/auth';


import { authNovelsCreator, AuthNovels } from './auth.novels';

import { locationNovelsCreator, LocationNovels } from './location.novels';
import { fulfillmentLocationNovelsCreator, FulfillmentLocationNovels } from './fulfillmentlocation.novels';

import { AppServices } from '../../app.services';

export interface AppNovels {
  authNovels: AuthNovels;
  
  locationNovels: LocationNovels;
  fulfillmentLocationNovels: FulfillmentLocationNovels;
}

const createAppNovels = (services: AppServices): AppNovels => {
  const authNovels = authNovelsCreator(services.authService);
  
  const locationNovels = locationNovelsCreator(services.locationService, services.persistenceService);
  const fulfillmentLocationNovels = fulfillmentLocationNovelsCreator(services.fulfillmentService, services.persistenceService);
  
 

  return {
    authNovels,
    
    locationNovels,
    fulfillmentLocationNovels,
  };
};

const addAppNovels = (
  novelMiddleware: NovelsMiddleware<StoreState, Action>,
  novels: AppNovels,
  
) => {
  const {
    authNovels,
    fulfillmentLocationNovels, 
    locationNovels,
    
   
  } = novels;
  
  novelMiddleware.add(
    AuthActionType.AUTHENTICATE_REQUEST,
    authNovels.authenticateNovel,
  );

  novelMiddleware.add(
    AuthActionType.LOG_OUT_REQUEST,
    authNovels.logOutNovel,
  );

  novelMiddleware.add(
    LocationActionType.SET_CURRENT_LOCATION_REQUEST,
    locationNovels.setCurrentLocationNovel,
  );

 

  novelMiddleware.add(
    LocationActionType.GET_CURRENT_LOCATION_REQUEST,
    locationNovels.getCurrentLocationNovel,
  );

 novelMiddleware.add(
    FulfillmentLocationActionType.GET_CURRENT_FULFILLMENT_LOCATION_REQUEST,
    fulfillmentLocationNovels.getCurrentFulfillmentLocationNovel
  );

 

  novelMiddleware.add(
    FulfillmentLocationActionType.SET_CURRENT_FULFILLMENT_LOCATION_REQUEST,
    fulfillmentLocationNovels.setCurrentFulfillmentLocationNovel,
  ); 



  
};

export {
  createAppNovels,
  addAppNovels,
};
