import { Reducer } from 'redux';
import { Action } from '../actions';
import { ActionType } from '../actions/fulfillmentLocation';
import { FulfillmentLocation } from '../../models/fulfillmentLocation';
import { FulfillmentLocationKey } from '../../models/utils';

export const nullLocation: FulfillmentLocation = {
  fulfillmentLocationId: 'null' as FulfillmentLocationKey,
  name: 'Loading ...',
  fulfillerId: '',
  dispatchAddressCountry: '',
  dispatchAddressPostalCode: '',
  displayName: ''
};

const fulfillmentLocationReducer: Reducer<FulfillmentLocation, Action> = (state = nullLocation as FulfillmentLocation, action) => {
  console.log('Looks like I was called action.type', action.type)
  switch (action.type) {
    case ActionType.GET_CURRENT_FULFILLMENT_LOCATION_REQUEST:
    case ActionType.GET_CURRENT_FULFILLMENT_LOCATION_FAILURE:
      return state;
    case ActionType.GET_CURRENT_FULFILLMENT_LOCATION_SUCCESS:
      console.log('returning action.data', action.data)
      return action.data;

     
    default:
      {
        // console.log('action.type ', action.type)
        // console.log('The state is ', state)
        return state;
      }
      
  }
};

export default fulfillmentLocationReducer;
