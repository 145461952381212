import { connect } from 'react-redux';
import { StoreState } from '../../common/commonModels';
import ToastsComponent from '../../components/utils/ToastsComponent';
import { toast } from '../../store/actions/toast';

const mapStateToProps = (state: StoreState) => ({
  toasts: state.toasts || [],
});

const mapDispatchToProps = {
  dismiss: toast.dismiss,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToastsComponent);
