import AuthService from './ports/AuthService';

import LocationService from './ports/LocationService';
import FulfillmentLocationService from './ports/FulfillmentLocationService';
import PersistenceService from './ports/PersistenceService';


import { StoreState } from './common/commonModels';
import { createReduxLocationProvider, createReduxFulfillmentLocationProvider } from './adapters/redux-providers';
import CentralizedAuthService from './adapters/CentralizedAuthService';
import SOPRestService from './adapters/SOPRestService';
import LocalStorageService from './adapters/LocalStorageService';

import Provider from './ports/Provider';
import { Location } from './models/location';
import { FulfillmentLocation } from './models/fulfillmentLocation';
import LoggingService from './ports/LoggingService';


export interface AppServices {
  authService: AuthService;
  
  locationService: LocationService;
  fulfillmentService: FulfillmentLocationService;
  persistenceService: PersistenceService;
  loggingService: LoggingService;
  providers: {
    locationProvider: Provider<Location>;
    fulfillmentLocationProvider: Provider<FulfillmentLocation>;
  };
}

export function createAppServices(store: { getState: () => StoreState }): AppServices {
  const locationProvider = createReduxLocationProvider(store);
  const fulfillmentLocationProvider = createReduxFulfillmentLocationProvider(store);
  const authService = new CentralizedAuthService();
  const backendService = new SOPRestService(authService, locationProvider);
  const persistenceService = new LocalStorageService();

  return {
    authService,
    persistenceService,
    
    fulfillmentService: backendService,
    
    locationService: backendService,
    loggingService: backendService,
    
    providers: {
      locationProvider,
      fulfillmentLocationProvider,
    },
  };
}
