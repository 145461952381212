import { EmptyAction, DataAction, ErrorAction } from './utils';
import { ShipmentGroup } from '../../models/shipment-group';

export enum ActionType {
  GET_SHIPMENT_GROUPS_REQUEST = 'GET_SHIPMENT_GROUPS/REQUEST',
  GET_SHIPMENT_GROUPS_FAILURE = 'GET_SHIPMENT_GROUPS/FAILURE',
  GET_SHIPMENT_GROUPS_SUCCESS = 'GET_SHIPMENT_GROUPS/SUCCESS',
}

export type GetShipmentGroupsRequestAction = EmptyAction<ActionType.GET_SHIPMENT_GROUPS_REQUEST>;
export type GetShipmentGroupsSuccessAction = DataAction<ActionType.GET_SHIPMENT_GROUPS_SUCCESS, ShipmentGroup[]>;
export type GetShipmentGroupsFailureAction = ErrorAction<ActionType.GET_SHIPMENT_GROUPS_FAILURE>;

export type GetShipmentGroupsAction =
  GetShipmentGroupsRequestAction |
  GetShipmentGroupsSuccessAction |
  GetShipmentGroupsFailureAction;

export type Action =
  GetShipmentGroupsAction;

export const getShipmentGroups = {
  request: (): GetShipmentGroupsRequestAction => ({
    type: ActionType.GET_SHIPMENT_GROUPS_REQUEST,
  }),
  failure: (error: string): GetShipmentGroupsFailureAction => ({
    error,
    type: ActionType.GET_SHIPMENT_GROUPS_FAILURE,
  }),
  success: (data: ShipmentGroup[]): GetShipmentGroupsSuccessAction => ({
    data,
    type: ActionType.GET_SHIPMENT_GROUPS_SUCCESS,
  }),
};
