import { DataAction } from './utils';
import { StoreState } from '../../common/commonModels';

export enum ActionType {
  RESTORE = 'RESTORE',
}

export type RestoreAction = DataAction<ActionType.RESTORE, Partial<StoreState>>;

export type Action = RestoreAction;

export const restore = (data: Partial<StoreState> = {}): RestoreAction => ({
  data, type: ActionType.RESTORE,
});
