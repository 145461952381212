import React from 'react';
import { Link } from 'react-router-dom';
import { FourOhFour } from '@cimpress/react-components';

// tslint:disable-next-line:variable-name
const NotFoundPage = () => (
  <div className="m-4">
    <FourOhFour
      message={
        <div>
          Oops! Something has gone wrong and the page you were looking for could not be displayed.
          <Link to="/"> Go back to homepage </Link>
        </div>
      }
    />
  </div>
);

export default NotFoundPage;
