import React, { ComponentType } from 'react';
import LoggingService from '../ports/LoggingService';

type Omit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;

// tslint:disable-next-line:variable-name
const LoggingProviderContext = React.createContext<LoggingService | null>(null);

export function useLogging(): LoggingService | null {
  const loggingService = React.useContext(LoggingProviderContext);
  return loggingService;
}

// tslint:disable-next-line:variable-name
export function withLogging<C extends any, Props = C extends ComponentType<infer P> ? P : never>(Component: C) {
  return (props: Omit<Props, 'loggingService'>) => {
    const loggingService = useLogging();
    return <Component {...props} loggingService={loggingService} />;
  };
}

interface LoggingProviderProps {
  children: JSX.Element | JSX.Element[];
  service: LoggingService;
}

// tslint:disable-next-line:function-name
export function LoggingProvider({ service, children }: LoggingProviderProps) {
  return (
    <LoggingProviderContext.Provider value={service}>
      {children}
    </LoggingProviderContext.Provider>
  );
}
