import TruckTemplateState from '../state/TruckTemplateState';
import { Action } from '../actions';
import { ActionType } from '../actions/truck';

const truckTemplateReducer = (state: TruckTemplateState = { templates: [] }, action: Action) => {
  switch (action.type) {
    case ActionType.GET_TRUCK_TEMPLATES_SUCCESS:
      return {
        templates: action.data,
      };
  }
  return state;
};

export default truckTemplateReducer;
