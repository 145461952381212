import { PalletBox } from './pallet-box';
import { Manifest } from './manifest';
import { LoadingRestriction } from './loading-restriction';
import { LocationKey } from './utils';
import { ShipmentGroup } from './shipment-group';

export enum TruckStatus {
  OPEN = 'open',
  CLOSED = 'closed',
}

export interface Truck {
  id: string;
  name: string;
  status: TruckStatus;
  locationKey: LocationKey;
  barcode: string;
  palletBoxes: PalletBox[];
  manifest?: Manifest;
  crossesBorder: boolean;
  linehaulCompanyId: string | null;
  customsBrokerId?: string;
  loadingRestrictions?: LoadingRestriction[];
  closedAt?: string;
  createdAt: string;
  updatedAt: string;
}

export interface Template {
  name: string;
  truckName?: string;
  loadingRestrictions: LoadingRestriction[];
  linehaulCompanyId: string;
  crossesBorder: boolean;
}

export interface TruckFormData {
  id: null | string;
  name: string;
  crossesBorder: boolean;
  linehaulCompanyId?: string | null;
  customsBrokerId?: string | null;
  loadingRestrictions?: LoadingRestriction[];
  locationKey?: LocationKey; // TODO: deprecate
}

export interface LoadTruckFormData {
  truck: Truck;
  shipmentGroup: ShipmentGroup;
  palletBoxCount: number;
}

// TODO: deprecate
export enum TruckFormType {
  ADD = 'add',
  EDIT = 'edit',
  MANIFEST = 'manifest',
  SHOW = 'show',
  LOAD = 'load',
}
