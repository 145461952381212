import { Action } from '../actions';
import { ActionType } from '../actions/persistence';

export function createPersistMiddleware<TState>(
  persistance: { set: (value: Partial<TState>) => void; get: () => Partial<TState>; },
  onType: Action['type'],
  ...fields: (keyof TState)[]
) {
  return (store: { getState: () => TState; }) =>
    (next: (action: Action) => any) =>
      (action: Action) => {
        if (action.type === ActionType.RESTORE) {
          action.data = persistance.get();
        }

        const nextResult = next(action);

        if (action.type === onType) {
          const itemsToPersist = fields.reduce((acc, key) => ({ ...acc, [key]: store.getState()[key] }), {});
          persistance.set(itemsToPersist);
        }

        return nextResult;
      };
}
