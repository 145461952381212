import React, { Component, ErrorInfo } from 'react';
import LoggingService from '../../ports/LoggingService';
import { withLogging } from '../../contexts/logging-provider';

interface Props {
  loggingService: LoggingService;
}

interface ErrorState {
  error?: { message: string };
}

class ErrorBoundary extends Component<Props, ErrorState> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(' invoked componentDidCatch')
    this.props.loggingService.log({
      error,
      message: 'Unexpected error',
      details: errorInfo.componentStack,
      level: 'error',
      url: window.location.href,
    });

    this.setState({
      error: {
        message: error.message,
      },
    });
  }

  render() {
    const { error } = this.state;

    if (error) {
      return (
        <div className="row mt-5">
          <div className="col-md-8 col-md-offset-2">
            <div className="card card-minimal">
              <div className="card-header" style={{ border: 'none' }} >
                <h5>
                  <i className="mr-2 fas fa-exclamation-triangle text-danger"></i>
                  Unexpected error
                </h5>
              </div>
              <div className="card-block">
                {error.message}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withLogging(ErrorBoundary);
