import { Reducer } from 'redux';
import { Action } from '../actions';
import { ActionType } from '../actions/auth';
import { User } from '../../models/auth';

const defaultState: User = {
  profile: {
    name: 'Not Authenticated',
    email: '',
    picture: '',
    sub: '',
    domain: '',
  },
  isLoggedIn: false,
};

const authReducer: Reducer<User, Action> = (state = defaultState, action) => {
  switch (action.type) {
    case ActionType.AUTHENTICATE_SUCCESS:
      return {
        isLoggedIn: true,
        profile: action.data,
      };

    case ActionType.AUTHENTICATE_FAILURE:
      return defaultState;

    case ActionType.LOG_OUT_SUCCESS:
      return defaultState;

    default:
      return state;
  }
};

export default authReducer;
