import { DataAction, ErrorAction, EmptyAction } from './utils';
import { Profile } from '../../models/auth';

export enum ActionType {
  AUTHENTICATE_REQUEST = 'AUTHENTICATE/REQUEST',
  AUTHENTICATE_SUCCESS = 'AUTHENTICATE/SUCCESS',
  AUTHENTICATE_FAILURE = 'AUTHENTICATE/FAILURE',

  LOG_OUT_REQUEST = 'LOG_OUT/REQUEST',
  LOG_OUT_SUCCESS = 'LOG_OUT/SUCCESS',
  LOG_OUT_FAILURE = 'LOG_OUT/FAILURE',
}

export type AuthenticateRequestAction = DataAction<ActionType.AUTHENTICATE_REQUEST, string>;
export type AuthenticateSuccessAction = DataAction<ActionType.AUTHENTICATE_SUCCESS, Profile>;
export type AuthenticateFailureAction = ErrorAction<ActionType.AUTHENTICATE_FAILURE>;

export type AuthenticateAction =
  AuthenticateRequestAction |
  AuthenticateSuccessAction |
  AuthenticateFailureAction;

export type LogOutRequestAction = EmptyAction<ActionType.LOG_OUT_REQUEST>;
export type LogOutSuccessAction = EmptyAction<ActionType.LOG_OUT_SUCCESS>;
export type LogOutFailureAction = ErrorAction<ActionType.LOG_OUT_FAILURE>;

export type LogOutAction =
  LogOutRequestAction |
  LogOutSuccessAction |
  LogOutFailureAction;

export type Action =
  AuthenticateAction | LogOutAction;

export const authenticate = {
  request: (nextUri: string): AuthenticateRequestAction => ({
    data: nextUri,
    type: ActionType.AUTHENTICATE_REQUEST,
  }),
  failure: (error: string): AuthenticateFailureAction => ({
    error,
    type: ActionType.AUTHENTICATE_FAILURE,
  }),
  success: (data: Profile): AuthenticateSuccessAction => ({
    data,
    type: ActionType.AUTHENTICATE_SUCCESS,
  }),
};

export const logOut = {
  request: (): LogOutRequestAction => ({
    type: ActionType.LOG_OUT_REQUEST,
  }),
  failure: (error: string): LogOutFailureAction => ({
    error,
    type: ActionType.LOG_OUT_FAILURE,
  }),
  success: (): LogOutSuccessAction => ({
    type: ActionType.LOG_OUT_SUCCESS,
  }),
};
