import { DataAction, ErrorAction, EmptyAction } from './utils';
import { ManifestEventLog } from '../../models/manifest';
import { ManifestActorName } from '../../ports/ManifestService';

export enum ActionType {
  GET_MANIFEST_LOG_REQUEST = 'GET_MANIFEST_LOG/REQUEST',
  GET_MANIFEST_LOG_FAILURE = 'GET_MANIFEST_LOG/FAILURE',
  GET_MANIFEST_LOG_SUCCESS = 'GET_MANIFEST_LOG/SUCCESS',

  MANIFEST_SINGLE_ACTOR_REQUEST = 'MANIFEST_SINGLE_ACTOR/REQUEST',
  MANIFEST_SINGLE_ACTOR_FAILURE = 'MANIFEST_SINGLE_ACTOR/FAILURE',
  MANIFEST_SINGLE_ACTOR_SUCCESS = 'MANIFEST_SINGLE_ACTOR/SUCCESS',
}

export type GetManifestEventLogRequestAction =
  DataAction<ActionType.GET_MANIFEST_LOG_REQUEST, string>;
export type GetManifestEventLogSuccessAction =
  DataAction<ActionType.GET_MANIFEST_LOG_SUCCESS, { log: ManifestEventLog; manifestId: string; }>;
export type GetManifestEventLogFailureAction =
  ErrorAction<ActionType.GET_MANIFEST_LOG_FAILURE>;

export type ManifestSingleActorRequestAction =
  DataAction<ActionType.MANIFEST_SINGLE_ACTOR_REQUEST, { manifestId: string; actorName: ManifestActorName; }>;
export type ManifestSingleActorSuccessAction =
  EmptyAction<ActionType.MANIFEST_SINGLE_ACTOR_SUCCESS>;
export type ManifestSingleActorFailureAction =
  ErrorAction<ActionType.MANIFEST_SINGLE_ACTOR_FAILURE>;

export type GetLoadingRestrictionsAction =
  GetManifestEventLogRequestAction |
  GetManifestEventLogSuccessAction |
  GetManifestEventLogFailureAction;

export type ManifestSingleActorAction =
  ManifestSingleActorRequestAction |
  ManifestSingleActorSuccessAction |
  ManifestSingleActorFailureAction;

export type Action =
  GetLoadingRestrictionsAction |
  ManifestSingleActorAction;

export const getManifestEventLog = {
  request: (data: string): GetManifestEventLogRequestAction => ({
    data,
    type: ActionType.GET_MANIFEST_LOG_REQUEST,
  }),
  success: (manifestId: string, log: ManifestEventLog): GetManifestEventLogSuccessAction => ({
    data: { manifestId, log },
    type: ActionType.GET_MANIFEST_LOG_SUCCESS,
  }),
  failure: (error: string): GetManifestEventLogFailureAction => ({
    error,
    type: ActionType.GET_MANIFEST_LOG_FAILURE,
  }),
};

export const manifestSingleActor = {
  request: (data: ManifestSingleActorRequestAction['data']): ManifestSingleActorRequestAction => ({
    data,
    type: ActionType.MANIFEST_SINGLE_ACTOR_REQUEST,
  }),
  success: (): ManifestSingleActorSuccessAction => ({
    type: ActionType.MANIFEST_SINGLE_ACTOR_SUCCESS,
  }),
  failure: (error: string): ManifestSingleActorFailureAction => ({
    error,
    type: ActionType.MANIFEST_SINGLE_ACTOR_FAILURE,
  }),
};
