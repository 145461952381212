import { EmptyAction } from './utils';

export enum ActionType {
  UI_LOCK = 'UI/LOCK',
  UI_UNLOCK = 'UI/UNLOCK',
}

export type EnableUiAction = EmptyAction<ActionType.UI_UNLOCK>;
export type DisableUiAction = EmptyAction<ActionType.UI_LOCK>;

export type Action = EnableUiAction | DisableUiAction;

export const ui = {
  unlock: (): EnableUiAction => ({
    type: ActionType.UI_UNLOCK,
  }),
  lock: (): DisableUiAction => ({
    type: ActionType.UI_LOCK,
  }),
};
