import React from 'react';
import { shapes } from '@cimpress/react-components';

const { Spinner } = shapes;
// tslint:disable-next-line:variable-name
const Loading = () => (
  <div className="d-flex justify-content-center">
    <Spinner />
  </div>
);

export default Loading;
