import { EmptyAction, DataAction, ErrorAction } from './utils';
import { CustomsBroker } from '../../models/customs-broker';

export enum ActionType {
  GET_CUSTOMS_BROKERS_REQUEST = 'GET_CUSTOMS_BROKERS/REQUEST',
  GET_CUSTOMS_BROKERS_FAILURE = 'GET_CUSTOMS_BROKERS/FAILURE',
  GET_CUSTOMS_BROKERS_SUCCESS = 'GET_CUSTOMS_BROKERS/SUCCESS',
}

export type GetCustomsBrokersRequestAction = EmptyAction<ActionType.GET_CUSTOMS_BROKERS_REQUEST>;
export type GetCustomsBrokersSuccessAction = DataAction<ActionType.GET_CUSTOMS_BROKERS_SUCCESS, CustomsBroker[]>;
export type GetCustomsBrokersFailureAction = ErrorAction<ActionType.GET_CUSTOMS_BROKERS_FAILURE>;

export type GetCustomsBrokersAction =
  GetCustomsBrokersRequestAction |
  GetCustomsBrokersSuccessAction |
  GetCustomsBrokersFailureAction;

export type Action =
  GetCustomsBrokersAction;

export const getCustomsBrokers = {
  request: (): GetCustomsBrokersRequestAction => ({
    type: ActionType.GET_CUSTOMS_BROKERS_REQUEST,
  }),
  failure: (error: string): GetCustomsBrokersFailureAction => ({
    error,
    type: ActionType.GET_CUSTOMS_BROKERS_FAILURE,
  }),
  success: (data: CustomsBroker[]): GetCustomsBrokersSuccessAction => ({
    data,
    type: ActionType.GET_CUSTOMS_BROKERS_SUCCESS,
  }),
};
