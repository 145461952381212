import { ManifestEventLogState } from '../state/manifest-event-log';
import { Action } from '../actions';
import { ActionType } from '../actions/manifest';

const manifestEventLogReducer = (state: ManifestEventLogState = { all: {}, isLoading: false }, action: Action) => {
  switch (action.type) {
    case ActionType.GET_MANIFEST_LOG_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionType.GET_MANIFEST_LOG_SUCCESS:
      const now = new Date();
      return {
        all: {
          ...state.all,
          [action.data.manifestId]: {
            log: action.data.log,
            updated: now,
          },
        },
        isLoading: false,
      };

    case ActionType.GET_MANIFEST_LOG_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default manifestEventLogReducer;
