import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { authenticate } from './store/actions/auth';
import NotFoundPage from './common/components/NotFoundPage';
import Loading from './common/components/Loading';
import NoLocationsPage from './common/components/NoLocationsPage';
import React, { useEffect } from 'react';
import ErrorBoundary from './components/utils/ErrorBoundary';
import { StoreState } from './common/commonModels';
import Header from './common/components/Header';
import { fullStoryIdentify } from './common/fullStory';
import { getCurrentFulfillmentLocation } from './store/actions/fulfillmentLocation';
import { nullLocation } from './store/reducers/fulfillment-location.reducer';
import { notFoundLocation} from './fulfillment-locations/fulfillmentLocationsModels'
import ToastsContainer from './containers/utils/ToastsContainer';
import { defaultUiOptions } from './store/reducers/ui-options.reducer';
import { Profile } from './models/auth';
import { getFeatureFlags } from './store/actions/feature';
import HomePage from './common/components/HomePage';
import info from './common/components/info';
import SubmittedRequests from './common/components/SubmittedRequests';

interface Props {
  userProfile: Profile;
  userIsLoggedIn: boolean;
  authenticate: Function;
  isFetchingCurrentLocation: boolean;
   getCurrentFulfillmentLocation: Function;
   noLocationAvailable: boolean;
 // uiOptions: UiOptions;
}

// tslint:disable-next-line:function-name
function App({
  userProfile,
  userIsLoggedIn,
  authenticate,
  isFetchingCurrentLocation,
  getCurrentFulfillmentLocation,
  noLocationAvailable,
//  uiOptions,
}: Props) {

  const dispatch = useDispatch();


  useEffect(
    () => {
      if (!isFetchingCurrentLocation && !noLocationAvailable) {
        dispatch(getFeatureFlags.request());
      }
    },
    [noLocationAvailable, isFetchingCurrentLocation],
  );

  useEffect(
    () => {
      if (userIsLoggedIn) {
         getCurrentFulfillmentLocation();
        fullStoryIdentify(userProfile);
      } else {
        authenticate();
      }
    },
    [userIsLoggedIn],
  );

  let routes = [
    // <Route exact path="/" render={() => <Redirect to= "/estimate-planning"/> }  key="homeRouteDefault" />,
    <Route exact path="/" render={(props) => <HomePage {... props} user={userProfile}/> }  key="homeRouteMain" />,
    // <Route exact path="/estimate-planning/:fulfilmentLocationId" render={(props) => <HomePage {... props} user={userProfile}/> }  key="homeRouteWithId" />,

    <Route exact path="/info" component={info} key="inforoute" />,
    <Route exact path="/submittedRequests" render={(props) => <SubmittedRequests {... props} user={userProfile}/> }  key="submittedRequests" />,
    
    <Route component={NotFoundPage} key="notFoundRoute" />,

    
  ];
  if (!userIsLoggedIn || isFetchingCurrentLocation) {
    routes = [<Route component={Loading} key="loadingRoute" />];
  }
  if (noLocationAvailable) {
    routes = [<Route component={NoLocationsPage} key="noLocationsRoute" />];
  }

  return (
    <div>
      <ToastsContainer/>
      {/* {modal &&
        <Modal
          {...modal.options}
          show={modal !== null}
          onRequestHide={hideModal}
          closeButton={true}
          closeOnOutsideClick={true}
        >
          {modal.content(hideModal)}
        </Modal>
      }
      <ModalContext.Provider value={
        (title, content, options) =>
          setModal({ content, options: { ...options, title } })
      }> */}
        <BrowserRouter>
          <ErrorBoundary>
            <Header />
            <div className="mt-2 content-wrapper col-md-10 offset-md-1">
             {/* <LoadingOverlay
                showOverlay={uiOptions.locked}
              />  */}
              <Switch>
                {routes}
              </Switch>
            </div>
          </ErrorBoundary>
        </BrowserRouter>
      {/* </ModalContext.Provider> */}
    </div>
  );
}

const mapStateToProps = (state: StoreState) => ({
  userProfile: state.user.profile,
  userIsLoggedIn: state.user.isLoggedIn,
  isFetchingCurrentLocation: state.fulfillmentLocation === nullLocation,
  noLocationAvailable: state.fulfillmentLocation === notFoundLocation,
  uiOptions: state.uiOptions || defaultUiOptions,
});

const mapDispatchToProps = {
  authenticate: authenticate.request,
   getCurrentFulfillmentLocation: getCurrentFulfillmentLocation.request,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
