import { EmptyAction, DataAction, ErrorAction } from './utils';
import { LinehaulCompany } from '../../models/linehaul-company';

export enum ActionType {
  GET_LINEHAUL_COMPANIES_REQUEST = 'GET_LINEHAUL_COMPANIES/REQUEST',
  GET_LINEHAUL_COMPANIES_FAILURE = 'GET_LINEHAUL_COMPANIES/FAILURE',
  GET_LINEHAUL_COMPANIES_SUCCESS = 'GET_LINEHAUL_COMPANIES/SUCCESS',
}

export type GetLinehaulCompaniesRequestAction =
  EmptyAction<ActionType.GET_LINEHAUL_COMPANIES_REQUEST>;
export type GetLinehaulCompaniesSuccessAction =
  DataAction<ActionType.GET_LINEHAUL_COMPANIES_SUCCESS, LinehaulCompany[]>;
export type GetLinehaulCompaniesFailureAction =
  ErrorAction<ActionType.GET_LINEHAUL_COMPANIES_FAILURE>;

export type GetLinehaulCompaniesAction =
  GetLinehaulCompaniesRequestAction |
  GetLinehaulCompaniesSuccessAction |
  GetLinehaulCompaniesFailureAction;

export type Action =
  GetLinehaulCompaniesAction;

export const getLinehaulCompanies = {
  request: (): GetLinehaulCompaniesRequestAction => ({
    type: ActionType.GET_LINEHAUL_COMPANIES_REQUEST,
  }),
  failure: (error: string): GetLinehaulCompaniesFailureAction => ({
    error,
    type: ActionType.GET_LINEHAUL_COMPANIES_FAILURE,
  }),
  success: (data: LinehaulCompany[]): GetLinehaulCompaniesSuccessAction => ({
    data,
    type: ActionType.GET_LINEHAUL_COMPANIES_SUCCESS,
  }),
};
