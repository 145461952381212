import React from 'react';
import ToastComponent from '../ToastComponent';
import { Toast } from '../../../models/utils';

import './ToastsComponent.css';

export interface ToastsProps {
  toasts: Toast[];
  dismiss: (toast: Toast) => void;
}

// tslint:disable-next-line:variable-name
const ToastsComponent = ({ toasts, dismiss }: ToastsProps) => {
  return (
    <div className="ToastsComponent">
      {toasts.map((toast, i) => <ToastComponent toast={toast} key={i} dismiss={() => dismiss(toast)} />)}
    </div>
  );
};

export default ToastsComponent;
