import { Location } from '../models/location';
import { FulfillmentLocation } from '../models/fulfillmentLocation'
import Provider from '../ports/Provider';

export const createReduxLocationProvider:
  <S extends { location: Location }>(store: { getState: () => S }) => Provider<Location> =
    ({ getState }) => () => getState().location;

    export const createReduxFulfillmentLocationProvider:
    <S extends { fulfillmentLocation: FulfillmentLocation }>(store: { getState: () => S }) => Provider<FulfillmentLocation> =
      ({ getState }) => () => getState().fulfillmentLocation;
