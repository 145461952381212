import { DataAction } from './utils';
import { Toast } from '../../models/utils';

export enum ActionType {
  TOAST_DISMISS = 'TOAST/DISMISS',
  TOAST_PUSH = 'TOAST/PUSH',
}

export type DismissToastAction = DataAction<ActionType.TOAST_DISMISS, Toast>;
export type PushToastAction = DataAction<ActionType.TOAST_PUSH, Toast>;

export type Action = DismissToastAction | PushToastAction;

export const toast = {
  dismiss: (data: Toast): DismissToastAction => ({
    data,
    type: ActionType.TOAST_DISMISS,
  }),
  push: (data: Toast): PushToastAction => ({
    data,
    type: ActionType.TOAST_PUSH,
  }),
};
