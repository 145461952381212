import AuthService from '../ports/AuthService';
import axios, { AxiosRequestConfig } from 'axios';

import serializeError from 'serialize-error';
import { Location } from '../models/location';

import {  Dictionary } from '../models/utils';
import LocationService from '../ports/LocationService';

import Provider from '../ports/Provider';
import LoggingService, { LogEvent } from '../ports/LoggingService';
import FulfillmentLocationService from '../ports/FulfillmentLocationService';
import { FulfillmentLocation } from '../models/fulfillmentLocation';


export enum HTTPMethod {
  GET = 'get',
  POST = 'post',
  PATCH = 'patch',
  DELETE = 'delete',
}

export interface BackendApiOptions {
  url: string;
  method: HTTPMethod;
  query?: Dictionary<unknown>;
  data?: unknown;
  responseType?: string;
}

export default class SOPRestService implements  LocationService, LoggingService, FulfillmentLocationService{
  
  constructor(
    private authService: AuthService,
    private locationProvider: Provider<Location>,
  ) {}

  get baseUrl() { return `/api/v1/locations/${this.locationProvider().key}`; }

  async getLocations() {
    return await this.request<Location[]>({
      method: HTTPMethod.GET,
      url: '/api/v1/locations',
    });
  }

  async getFulfillmentLocations () {
    return await this.request<FulfillmentLocation[]>({
      method: HTTPMethod.GET,
      url: '/api/v1/locations/fulfillmentlocations',
    });
  }

  async log(event: LogEvent) {
    const data = {
      ...event,
      timestamp: new Date().toISOString(),
    };

    // use a specific serializer because error properties are not enumerable
    if (data.error) {
      data.error = serializeError(data.error);
    }

    await this.request<void>({
      data,
      method: HTTPMethod.POST,
      url: '/api/v1/ui-log',
    });
  }

  private getDefaultOptions() {
    const authorization = this.authService.getAuthHeader();
    return {
      headers: {
        authorization,
      },
    };
  }

  private async request<T>(options: BackendApiOptions) {
    const axiosOptions: AxiosRequestConfig = {
      ...this.getDefaultOptions(),
      url:  options.url,
      method: options.method,
      params: options.query,
      data: options.data,
      responseType: options.responseType,
    };

    try {
      const result = await axios.request<T>(axiosOptions);
      return result.data;
    } catch ({ message, result, response }) {
      if (result) {
        throw {
          message,
          correlationId: result.data.correlationId,
          details: result.data.details,
        };
      } else if (response) {
        throw {
          message,
          correlationId: response.data.correlationId,
          details: response.data.details,
        };
      } else {
        throw {
          message,
        };
      }
    }
  }
}
