import { ToastState } from '../state/toasts';
import { Action } from '../actions';
import { ActionType } from '../actions/toast';

const toastReducer = (state: ToastState = [], action: Action | any) => {
  switch (action.type) {
    case ActionType.TOAST_DISMISS:
      return state.filter(toast => toast !== action.data);

    case ActionType.TOAST_PUSH:
      return [
        ...state,
        action.data,
      ];
    default:
      return state;
  }
};

export default toastReducer;
