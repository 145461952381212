import React, { Component } from 'react';
import axios from 'axios';
import { Table, Checkbox } from '@cimpress/react-components';
import authApi from '../../auth/authApi';
import { Button } from '@cimpress/react-components';

const columns = [
    { Header: 'Status', accessor: 'status' },
    { Header: 'Fulfillment Location', accessor: 'location' },
    { Header: 'Carrier', accessor: 'carrier' },
    { Header: 'Country', accessor: 'country' },
    { Header: 'User', accessor: 'user' },
    { Header: 'Last Updated', accessor: 'lastUpdated' }
];

// const data = []

class SubmittedRequests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            data: []
        };
    }
    async componentDidMount() {
        //call to api       
        this.getStatus();

    }
    getStatus = async () => {
        const authorization = authApi.getAuthHeader();
        axios.defaults.headers.common['Authorization'] = authorization;
        const response = await axios.get('api/v1/processStatus')
        console.log("response.data:" + response.data.details + " len:" + response.data.details.length);
        if (response.data.details.length > 0) {
            this.setState({
                data: response.data.details
            })
            // console.log(this.state.data);
        }
    }
    render() {
        return (
            <div>
                <Button onClick={this.getStatus} >Refresh Status</Button> <br /><br />
                <Table
                    columns={columns}
                    data={this.state.data}
                />
            </div>
        )
    }
}

export default SubmittedRequests