import { ActionType as TruckActionType } from '../actions/truck';
import { Action } from '../actions';
import { Truck, TruckStatus } from '../../models/truck';
import { Reducer } from 'redux';
import { TruckState } from '../state/truck';
import { Dictionary } from '../../models/utils';

const initialState: TruckState = {
  all: {},
  isLoading: false,
};

const truckReducer: Reducer<TruckState, Action> = (state: TruckState = initialState, action): TruckState => {
  switch (action.type) {
    case TruckActionType.GET_TRUCKS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case TruckActionType.GET_TRUCKS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case TruckActionType.GET_TRUCKS_SUCCESS:
      const { data } = action;
      const all: Dictionary<Truck> = {};
      data.forEach(truck =>
        all[truck.id] = {
          ...state.all[truck.id],
          ...truck,
        },
      );
      return {
        all,
        isLoading: false,
      };

    case TruckActionType.GENERATE_MANIFEST_SUCCESS:
      return {
        ...state,
        all: {
          ...state.all,
          [action.data.id]: {
            ...state.all[action.data.id],
            status: TruckStatus.CLOSED,
          },
        },
      };

    case TruckActionType.PATCH_TRUCK_SUCCESS:
    case TruckActionType.CREATE_TRUCK_SUCCESS:
      return {
        ...state,
        all: {
          ...state.all,
          [action.data.id]: {
            ...state.all[action.data.id],
            ...action.data,
          },
        },
      };

    case TruckActionType.PATCH_TRUCK_FAILURE:
    case TruckActionType.CREATE_TRUCK_FAILURE:
    case TruckActionType.GENERATE_MANIFEST_FAILURE:
    default: {
      return state;
    }
  }
};

export default truckReducer;
