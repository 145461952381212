import React from 'react';
import { Transition } from 'react-transition-group';

import './CopyComponent.css';

interface CopyProps {
  value: string;
  title?: string;
}

const copy = (event: React.MouseEvent, value: string, element: React.RefObject<HTMLInputElement>) => {
  event.preventDefault();
  if (element.current) {
    element.current.value = value;
    element.current.select();
    document.execCommand('copy');
    return true;
  }
  return false;
};

// tslint:disable-next-line:variable-name
const CopyComponent = ({ value, title }: CopyProps) => {
  const ref = React.createRef<HTMLInputElement>();
  const [copied, setCopied] = React.useState(false);
  return (
    <span className="CopyComponent">
      <a
        title={title}
        href="#"
        onClick={event => setCopied(copy(event, value, ref))}
      >
        <span className="fa-stack">
          <i className="fas fa-stack-1x fa-copy"></i>
          <Transition
            in={copied}
            timeout={1000}
            onEnter={ref => ref.classList.add('copied')}
            onEntered={() => setCopied(false)}
            onExiting={ref => ref.classList.add('fade')}
            onExited={ref => ref.classList.remove('copied', 'fade')}
          >
            <i className="fas fa-stack-1x fa-check text-success"></i>
          </Transition>
        </span>
      </a>
      <input
        type="text"
        ref={ref}
        style={{
          position: 'absolute',
          left: '0',
          width: '100%',
          top: '0',
          height: '100%',
          opacity: 0,
          pointerEvents: 'none',
        }}
      />
    </span>
  );
};

export default CopyComponent;
