import { actionTypes } from './apiStatusActions';
import { Reducer } from 'redux';

function actionTypeEndsInSuccess(type: string) {
  return type.substring(type.length - 8) === '_SUCCESS';
}

const apiCallStatusReducer: Reducer = (state = 0, action) => {
  if (action.type === actionTypes.BEGIN_API_CALL) {
    return state + 1;
  }

  if (action.type === actionTypes.API_CALL_ERROR || actionTypeEndsInSuccess(action.type)) {
    return state - 1;
  }

  return state;
};

export default apiCallStatusReducer;
