export const actionTypes = {
  BEGIN_API_CALL: 'BEGIN_API_CALL',
  API_CALL_ERROR: 'API_CALL_ERROR',
};

export function beginApiCall() {
  return { type: actionTypes.BEGIN_API_CALL };
}

export function apiCallError() {
  return { type: actionTypes.API_CALL_ERROR };
}
