import React from 'react';
import { connect } from 'react-redux';
// Disable rule for components
// tslint:disable-next-line:import-name
import ReactPlatformHeader from '@cimpress-technology/react-platform-header/lib/Header';
import { logOut } from '../../store/actions/auth';
import { Link } from 'react-router-dom';
import { StoreState } from '../commonModels';
import { Profile } from '../../models/auth';



// tslint:disable-next-line:variable-name
const Header = ({
  userProfile,
  userIsLoggedIn,
  onLogout,
 // selectedLocationName,
}) => {
 // const selectedLocationText = `• ${selectedLocationName}`;

  // const appLinks = [
  //   <Link to="/">Home</Link>,
  //   <Link to="/submittedRequests">Submitted Requests</Link>,
   
  // ];

  const appLinks = [
    {
      id: 'home',
      type: 'default',
      content: <Link to="/">Home</Link>,
    },
    {
      id: 'submitted',
      type: 'default',
      content: <Link to="/submittedRequests">Request Status</Link>,
    }
  ];


  return (
    <div>
    <ReactPlatformHeader
      responsive
      appTitle={`Estimate Planning`}
      profile={userProfile}
      onLogInClicked={() => { }}
      onLogOutClicked={onLogout}
      isLoggedIn={userIsLoggedIn}
      appLinks={appLinks}
      onSettingsClicked={() => {}}
    />
    
    </div>
  );
};

const mapStateToProps = (state) => ({
  userProfile: state.user.profile,
  userIsLoggedIn: state.user.isLoggedIn,
  selectedLocationName: state.fulfillmentLocation.name,
});

const mapDispatchToProps = {
  onLogout: logOut.request,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
