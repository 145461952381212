import { Action } from '../actions';
import { ActionType } from '../actions/ui-options';
import { Reducer } from 'redux';
import { UiOptions } from '../state/ui-options';

export const defaultUiOptions = {
  locked: false,
};

const uiReducer: Reducer<UiOptions, Action> = (state = defaultUiOptions, action) => {
  switch (action.type) {
    case ActionType.UI_LOCK:
      return { ...state, locked: true };
    case ActionType.UI_UNLOCK:
      return { ...state, locked: false };
    default:
      return state;
  }
};

export default uiReducer;
