import { Subject } from 'rxjs/internal/Subject';
import { filter } from 'rxjs/internal/operators/filter';
import { take } from 'rxjs/internal/operators/take';
import { timeout } from 'rxjs/internal/operators/timeout';
import { tap } from 'rxjs/internal/operators/tap';

export interface Action<T extends string = string> {
  type: T;
}

export interface UntilActionOfType<TActions extends Action = Action> {
  <Type extends TActions['type']>(type: Type, timeout?: number): Promise<TActions & Action<Type>>;
  <Type extends TActions['type']>(type: Type[], timeout?: number): Promise<TActions & Action<Type>>;
  (type: RegExp, timeout?: number): Promise<TActions>;
}

export interface Novel<Input extends Action = Action, Output extends Action = Input> {
  (
    action: Input,
    dispatch: (a: Output) => void,
    untilActionOfType: UntilActionOfType<Output>,
  ): Promise<void> | void;
}

export interface Add<TActions extends Action = Action> {
  <TAction extends TActions, Type extends TAction['type']>(
    type: Type, novel: Novel<TAction, TActions>,
  ): void;
  <TAction extends TActions, Type extends TAction['type']>(
    type: Type[], novel: Novel<TAction, TActions>,
  ): void;
  (
    type: RegExp, novel: Novel<TActions>,
  ): void;
}

export interface NovelsMiddleware<TState, TActions extends Action = Action> {
  (store: { getState: () => TState; dispatch: (action: TActions) => void }):
    (next: (a: TActions) => any) => (action: TActions) => any;
  add: Add<TActions>;
  flush: () => Promise<void>;
}

export const createNovelsMiddleware =
  <TState, TActions extends { type: string }>(
    onError?: (e: Error, action: TActions, novel: Novel<TActions>) => void,
  ): NovelsMiddleware<TState, TActions> => {
    const typeNovels = new Map<TActions['type'], Novel<TActions>[]>();
    const regExpNovels = new Map<RegExp, Novel<TActions>[]>();
    const promises = new Set<Promise<any>>();

    const actions$ = new Subject<TActions>();
    const untilActionOfType: UntilActionOfType<TActions> =
      (type: TActions['type'] | TActions['type'][] | RegExp, delay?: number) => {
        return actions$
          .pipe(
            type instanceof RegExp
              ? filter<TActions>(action => type.test(action.type))
              : typeof type === 'string'
                ? filter<TActions>(action => action.type === type)
                : filter<TActions>(action => type.indexOf(action.type) !== -1),
            take(1),
            delay ? timeout(delay) : tap(),
          )
          .toPromise();
      };

    const matchLiteralNovels = (action: TActions, dispatch: (action: TActions) => void) => {
      const matchingTypeNovels = typeNovels.get(action.type);
      if (matchingTypeNovels) {
        matchingTypeNovels.forEach(novel =>
          executeNovel(novel, action, dispatch),
        );
      }
    };

    const matchRegexNovels = (action: TActions, dispatch: (action: TActions) => void) => {
      for (const regExp of regExpNovels.keys()) {
        if (regExp.test(action.type)) {
          const matchingNovels = regExpNovels.get(regExp)!;
          matchingNovels.forEach(novel =>
            executeNovel(novel, action, dispatch),
          );
        }
      }
    };

    const executeNovel = (novel: Novel<TActions>, action: TActions, dispatch: (action: TActions) => void) => {
      const promise: Promise<any> = Promise.resolve()
        .then(() => novel(action, dispatch, untilActionOfType))
        .catch(onError ? (err) => onError(err, action, novel) : undefined)
        .then(() => promises.delete(promise));
      promises.add(promise);
    };

    const novelsMiddleware: NovelsMiddleware<TState, TActions> =
      ({ dispatch }: { getState: () => TState; dispatch: (action: TActions) => void }) =>
        (next: (a: TActions) => any) => (action: TActions): any => {
          const nextResult = next(action);
          actions$.next(action);
          matchLiteralNovels(action, dispatch);
          matchRegexNovels(action, dispatch);
          return nextResult;
        };

    novelsMiddleware.add = <TAction extends TActions, Type extends TAction['type']>(
      actionTypes: RegExp | Type | Type[],
      novel: any,
    ) => {
      if (actionTypes instanceof RegExp) {
        regExpNovels.has(actionTypes)
          ? regExpNovels.get(actionTypes)!.push(novel)
          : regExpNovels.set(actionTypes, [novel]);
      } else {
        const types = typeof actionTypes === 'string' ? [actionTypes] : actionTypes;
        types.forEach(
          actionType =>
            typeNovels.has(actionType)
              ? typeNovels.get(actionType)!.push(novel)
              : typeNovels.set(actionType, [novel]),
        );
      }
    };

    novelsMiddleware.flush = () =>
      Promise.all([...promises.values()]).then(() => undefined);

    return novelsMiddleware;
  };
