import { apiCall } from '../actions/api-call';
import { Action } from '../actions';
import { Novel } from '../middlewares/novels';
import { GetCurrentLocationRequestAction, getCurrentLocation, setCurrentLocation, SetCurrentLocationRequestAction } from '../actions/location';
import LocationService from '../../ports/LocationService';
import PersistenceService from '../../ports/PersistenceService';
import { LocationKey } from '../../models/utils';
import { Location } from '../../models/location';

export const CURRENT_LOCATION_KEY = 'current-location-key';

export const notFoundLocation: Location = {
  key: 'not found' as LocationKey,
  name: 'not found',
  localeSettings: {
    dateFormat: 'YYYY-MM-DD',
    timezone: 'Etc/UTC',
  },
};

export interface LocationNovels {
  setCurrentLocationNovel: Novel<SetCurrentLocationRequestAction, Action>;
  getCurrentLocationNovel: Novel<GetCurrentLocationRequestAction, Action>;
}

export const locationNovelsCreator = (
  locationService: LocationService,
  persistenceService: PersistenceService,
): LocationNovels => {
  const setCurrentLocationNovel = (
    { data }: SetCurrentLocationRequestAction,
    dispatch: (action: Action) => void,
  ) => {
    try {
      persistenceService.setData(
        CURRENT_LOCATION_KEY,
        data.key,
      );
      dispatch(setCurrentLocation.success());
    } catch ({ message }) {
      dispatch(setCurrentLocation.failure(message));
    }
  };

  const getCurrentLocationNovel = async (
    {}: GetCurrentLocationRequestAction,
    dispatch: (action: Action) => void,
  ) => {
    try {
      let locations: Location[];
      try {
        dispatch(apiCall());
        locations = await locationService.getLocations();
        dispatch(apiCall.success());
      } catch (err) {
        dispatch(apiCall.failure(err.message));
        throw err;
      }
      const currentLocationKey = persistenceService.getData(CURRENT_LOCATION_KEY);
      const currentLocation =
        locations.find(location => location.key === currentLocationKey) ||
        (locations.length ? locations[0] : notFoundLocation);

      persistenceService.setData(CURRENT_LOCATION_KEY, currentLocation.key);
      dispatch(getCurrentLocation.success(currentLocation));
    } catch ({ message }) {
      dispatch(getCurrentLocation.failure(message));
    }
  };

  return {
    getCurrentLocationNovel,
    setCurrentLocationNovel,
  };
};
