import React from 'react';

const noLocationsPage = () => (
  <div className="mx-auto">
    <h2>No locations configured</h2>
    <div className="mt-3">
      <p>Please contact&nbsp;
        <a href="mailto:Logistics Support">logisticssupport@cimpress.com</a>
        &nbsp;for assistance.</p>
    </div>
  </div>
);

export default noLocationsPage;
