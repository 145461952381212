import { User } from '../models/auth';
import { TruckState } from '../store/state/truck';
import { LinehaulCompanyState } from '../store/state/linehaul-company';
import { CustomsBrokerState } from '../store/state/customs-broker';
import { LoadingRestrictionState } from '../store/state/loading-restriction';
import { Location } from '../models/location';
import TruckTemplateState from '../store/state/TruckTemplateState';
import { ToastState } from '../store/state/toasts';
import { UiOptions } from '../store/state/ui-options';
import { ShipmentGroupState } from '../store/state/shipment-group';
import { ManifestEventLogState } from '../store/state/manifest-event-log';
import { Dictionary } from '../models/utils';
import { FulfillmentLocation } from '../models/fulfillmentLocation';

export interface StoreState {
  apiCallsInProgress: Number;
  user: User;
  location: Location;
  fulfillmentLocation: FulfillmentLocation
  shipmentGroups: ShipmentGroupState;
  trucks: TruckState;
  linehaulCompanies: LinehaulCompanyState;
  customsBrokers: CustomsBrokerState;
  uiOptions: UiOptions;
  loadingRestrictions: LoadingRestrictionState;
  manifestEventLogs: ManifestEventLogState;
  truckTemplate: TruckTemplateState;
  toasts: ToastState;
  features: Dictionary<boolean>;
}

export enum Icon {
  Unsorted = 'fas fa-3x fa-dolly text-danger',
  Open = 'fas fa-2x fa-box-open text-info',
  Closed = 'fas fa-2x fa-box text-success',
  Load = 'far fa-3x fa-plus-square text-primary',
  Progress = 'fas fa-3x fa-cog fa-spin text-info',
  Success = 'far fa-3x fa-check-circle text-success',
  Action = 'fas fa-3x fa-exclamation-circle text-warning',
  Error = 'fas fa-3x fa-exclamation-triangle text-danger',
  Document = 'fa-3x fas fa-clipboard-list',
  BorderDocument = 'fa-3x fas fa-passport',
  Truck = 'mb-3 fas fa-3x fa-truck text-success',

  CategoryBorder = 'fa-3x fas fa-passport',
  CategoryCarrier = 'fa-3x fas fa-clipboard',
  CategoryShipment = 'fa-3x fas fa-box',

  ActiveCategoryBorder = 'fa-3x fas fa-passport text-info',
  ActiveCategoryCarrier = 'fa-3x fas fa-clipboard text-info',
  ActiveCategoryShipment = 'fa-3x fas fa-box text-info',
}

export interface SelectOption<T = string> {
  label: string;
  value: T;
}

export interface Tag {
  key: string;
  value: string;
}

// A DateString represents a location dependant date (without a time.)
// For example, 2019-07-25 in Zurich represents the 24h period from 2019-07-24T22:00:00
// until 2019-07-25T22:00:00 with the end being exclusively compared.

// See https://basarat.gitbooks.io/typescript/content/docs/tips/nominalTyping.html
// also see https://github.com/Microsoft/TypeScript/issues/28079: "The nominal typing is example above is flawed"
interface Nominal<T/*: must be an enum or a literal */> {
  'nominal structural brand': T;
}
export enum DateStringBrand { }
export type DateString = string & Nominal<DateStringBrand>;
