import { Reducer } from 'redux';
import { Action } from '../actions';
import { ActionType } from '../actions/loading-restriction';
import { LoadingRestrictionState } from '../state/loading-restriction';
import { LoadingRestriction } from '../../models/loading-restriction';
import { Dictionary } from '../../models/utils';

const defaultState: LoadingRestrictionState = {
  all: {},
  isLoading: false,
};

const loadingRestrictionReducer: Reducer<LoadingRestrictionState, Action> = (state = defaultState, action) => {
  switch (action.type) {
    case ActionType.GET_LOADING_RESTRICTIONS_REQUEST:
      return {
        ...state, isLoading: true,
      };
    case ActionType.GET_LOADING_RESTRICTIONS_FAILURE:
      return {
        ...state, isLoading: false,
      };
    case ActionType.GET_LOADING_RESTRICTIONS_SUCCESS:
      const all: Dictionary<Dictionary<LoadingRestriction>> = {};
      action.data.forEach(lr => {
        if (!all[lr.restrictionType]) {
          all[lr.restrictionType] = {};
        }
        all[lr.restrictionType][lr.value] = lr;
      });
      return {
        all,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default loadingRestrictionReducer;
