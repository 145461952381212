import { centralizedAuth as CentralizedAuth } from '@cimpress/simple-auth-wrapper';
import AuthService from '../ports/AuthService';
import { Profile } from '../models/auth';

export default class CentralizedAuthService implements AuthService {
  private clientId = '0fpkluGMXts5jkvXd5I1prfdcNb4WFhF';

  private auth!: any;
  private location!: Location;

  constructor() {
    this.auth = new CentralizedAuth({
      clientID: this.clientId,
      redirectRoute: '/',
    });
    this.location = window.location;
    this.auth.on(
      'tokenExpired',
      () => this.auth.login({
        nextUri: `${this.location.pathname}${this.location.search}${this.location.hash}`,
        forceLogin: true,
      }),
    );
  }

  getAccessToken(): string {
    return this.auth.getAccessToken();
  }

  async ensureAuthentication(nextUri: string) {
    return await this.auth.ensureAuthentication({ nextUri });
  }

  getProfile(): Profile {
    const rawProfile = this.auth.getProfile();
    return {
      email: rawProfile.email,
      name: rawProfile.name,
      sub: rawProfile.sub,
      picture: rawProfile.picture,
      domain: rawProfile.domain,
    };
  }

  isLoggedIn() {
    return this.auth.isLoggedIn();
  }

  async login() {
    return await this.auth.login();
  }

  logout() {
    this.auth.logout('/', false);
  }

  getAuthHeader(): string {
    return `Bearer ${this.getAccessToken()}`;
  }
}
