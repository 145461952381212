import { DateString } from '../../models/utils';
import { DataAction, ErrorAction, EmptyAction } from './utils';
import { Truck, TruckFormData, LoadTruckFormData, Template } from '../../models/truck';

export enum ActionType {
  GET_TRUCKS_REQUEST = 'GET_TRUCKS/REQUEST',
  GET_TRUCKS_FAILURE = 'GET_TRUCKS/FAILURE',
  GET_TRUCKS_SUCCESS = 'GET_TRUCKS/SUCCESS',

  GET_TRUCK_TEMPLATES_REQUEST = 'GET_TRUCK_TEMPLATES/REQUEST',
  GET_TRUCK_TEMPLATES_FAILURE = 'GET_TRUCK_TEMPLATES/FAILURE',
  GET_TRUCK_TEMPLATES_SUCCESS = 'GET_TRUCK_TEMPLATES/SUCCESS',

  CREATE_TRUCK_REQUEST = 'CREATE_TRUCK/REQUEST',
  CREATE_TRUCK_FAILURE = 'CREATE_TRUCK/FAILURE',
  CREATE_TRUCK_SUCCESS = 'CREATE_TRUCK/SUCCESS',

  PATCH_TRUCK_REQUEST = 'PATCH_TRUCK/REQUEST',
  PATCH_TRUCK_FAILURE = 'PATCH_TRUCK/FAILURE',
  PATCH_TRUCK_SUCCESS = 'PATCH_TRUCK/SUCCESS',

  LOAD_TRUCK_REQUEST = 'LOAD_TRUCK/REQUEST',
  LOAD_TRUCK_SUCCESS = 'LOAD_TRUCK/SUCCESS',
  LOAD_TRUCK_FAILURE = 'LOAD_TRUCK/FAILURE',

  GENERATE_MANIFEST_REQUEST = 'GENERATE_MANIFEST/REQUEST',
  GENERATE_MANIFEST_SUCCESS = 'GENERATE_MANIFEST/SUCCESS',
  GENERATE_MANIFEST_FAILURE = 'GENERATE_MANIFEST/FAILURE',
}

export type GetTrucksRequestAction = DataAction<ActionType.GET_TRUCKS_REQUEST, DateString>;
export type GetTrucksSuccessAction = DataAction<ActionType.GET_TRUCKS_SUCCESS, Truck[]>;
export type GetTrucksFailureAction = ErrorAction<ActionType.GET_TRUCKS_FAILURE>;

export type GetTrucksAction =
  GetTrucksRequestAction |
  GetTrucksSuccessAction |
  GetTrucksFailureAction;

export type GetTruckTemplatesRequestAction = EmptyAction<ActionType.GET_TRUCK_TEMPLATES_REQUEST>;
export type GetTruckTemplatesSuccessAction = DataAction<ActionType.GET_TRUCK_TEMPLATES_SUCCESS, Template[]>;
export type GetTruckTemplatesFailureAction = ErrorAction<ActionType.GET_TRUCK_TEMPLATES_FAILURE>;

export type GetTruckTemplatesAction =
  GetTruckTemplatesRequestAction |
  GetTruckTemplatesSuccessAction |
  GetTruckTemplatesFailureAction;

export type CreateTruckRequestAction = DataAction<ActionType.CREATE_TRUCK_REQUEST, TruckFormData>;
export type CreateTruckSuccessAction = DataAction<ActionType.CREATE_TRUCK_SUCCESS, Truck>;
export type CreateTruckFailureAction = ErrorAction<ActionType.CREATE_TRUCK_FAILURE>;

export type CreateTruckAction =
  CreateTruckRequestAction |
  CreateTruckSuccessAction |
  CreateTruckFailureAction;

export type PatchTruckRequestAction = DataAction<ActionType.PATCH_TRUCK_REQUEST, TruckFormData>;
export type PatchTruckSuccessAction = DataAction<ActionType.PATCH_TRUCK_SUCCESS, Truck>;
export type PatchTruckFailureAction = ErrorAction<ActionType.PATCH_TRUCK_FAILURE>;

export type PatchTrucksAction =
  PatchTruckRequestAction |
  PatchTruckSuccessAction |
  PatchTruckFailureAction;

export type LoadTruckRequestAction = DataAction<ActionType.LOAD_TRUCK_REQUEST, LoadTruckFormData>;
export type LoadTruckSuccessAction = DataAction<ActionType.LOAD_TRUCK_SUCCESS, LoadTruckFormData>;
export type LoadTruckFailureAction = ErrorAction<ActionType.LOAD_TRUCK_FAILURE>;

export type LoadTrucksAction =
  LoadTruckRequestAction |
  LoadTruckSuccessAction |
  LoadTruckFailureAction;

export type GenerateManifestRequestAction = DataAction<ActionType.GENERATE_MANIFEST_REQUEST, Truck>;
export type GenerateManifestSuccessAction = DataAction<ActionType.GENERATE_MANIFEST_SUCCESS, Truck>;
export type GenerateManifestFailureAction = ErrorAction<ActionType.GENERATE_MANIFEST_FAILURE>;

export type GenerateManifestAction =
  GenerateManifestRequestAction |
  GenerateManifestSuccessAction |
  GenerateManifestFailureAction;

export type Action =
  GetTrucksAction |
  GetTruckTemplatesAction |
  CreateTruckAction |
  PatchTrucksAction |
  LoadTrucksAction |
  GenerateManifestAction;

export const getTrucks = {
  request: (data: DateString): GetTrucksRequestAction => ({
    data,
    type: ActionType.GET_TRUCKS_REQUEST,
  }),
  failure: (error: string): GetTrucksFailureAction => ({
    error,
    type: ActionType.GET_TRUCKS_FAILURE,
  }),
  success: (data: Truck[]): GetTrucksSuccessAction => ({
    data,
    type: ActionType.GET_TRUCKS_SUCCESS,
  }),
};

export const getTruckTemplates = {
  request: (): GetTruckTemplatesRequestAction => ({
    type: ActionType.GET_TRUCK_TEMPLATES_REQUEST,
  }),
  failure: (error: string): GetTruckTemplatesFailureAction => ({
    error,
    type: ActionType.GET_TRUCK_TEMPLATES_FAILURE,
  }),
  success: (data: Template[]): GetTruckTemplatesSuccessAction => ({
    data,
    type: ActionType.GET_TRUCK_TEMPLATES_SUCCESS,
  }),
};

export const createTruck = {
  request: (data: TruckFormData): CreateTruckRequestAction => ({
    data,
    type: ActionType.CREATE_TRUCK_REQUEST,
  }),
  failure: (error: string): CreateTruckFailureAction => ({
    error,
    type: ActionType.CREATE_TRUCK_FAILURE,
  }),
  success: (data: Truck): CreateTruckSuccessAction => ({
    data,
    type: ActionType.CREATE_TRUCK_SUCCESS,
  }),
};

export const patchTruck = {
  request: (data: TruckFormData): PatchTruckRequestAction => ({
    data,
    type: ActionType.PATCH_TRUCK_REQUEST,
  }),
  failure: (error: string): PatchTruckFailureAction => ({
    error,
    type: ActionType.PATCH_TRUCK_FAILURE,
  }),
  success: (data: Truck): PatchTruckSuccessAction => ({
    data,
    type: ActionType.PATCH_TRUCK_SUCCESS,
  }),
};

export const loadTruck = {
  request: (data: LoadTruckFormData): LoadTruckRequestAction => ({
    data,
    type: ActionType.LOAD_TRUCK_REQUEST,
  }),
  failure: (error: string): LoadTruckFailureAction => ({
    error,
    type: ActionType.LOAD_TRUCK_FAILURE,
  }),
  success: (data: LoadTruckFormData): LoadTruckSuccessAction => ({
    data,
    type: ActionType.LOAD_TRUCK_SUCCESS,
  }),
};

export const generateManifest = {
  request: (data: Truck): GenerateManifestRequestAction => ({
    data,
    type: ActionType.GENERATE_MANIFEST_REQUEST,
  }),
  failure: (error: string): GenerateManifestFailureAction => ({
    error,
    type: ActionType.GENERATE_MANIFEST_FAILURE,
  }),
  success: (data: Truck): GenerateManifestSuccessAction => ({
    data,
    type: ActionType.GENERATE_MANIFEST_SUCCESS,
  }),
};
