import { ErrorAction } from './utils';
import { Action } from 'redux';

export enum ActionTypes {
  API_CALL_REQUEST = 'API_CALL/REQUEST',
  API_CALL_SUCCESS = 'API_CALL/SUCCESS',
  API_CALL_FAILURE = 'API_CALL/FAILURE',
}

export type ApiCallRequestAction = Action<ActionTypes.API_CALL_REQUEST>;
export type ApiCallSuccessAction = Action<ActionTypes.API_CALL_SUCCESS>;
export type ApiCallFailureAction = ErrorAction<ActionTypes.API_CALL_FAILURE>;
export type ApiCallAction =
  ApiCallRequestAction |
  ApiCallSuccessAction |
  ApiCallFailureAction;

export type Action = ApiCallAction;

export const apiCall = (): ApiCallRequestAction => ({
  type: ActionTypes.API_CALL_REQUEST,
});

apiCall.failure = (error: string): ApiCallFailureAction => ({
  error,
  type: ActionTypes.API_CALL_FAILURE,
});

apiCall.success = (): ApiCallSuccessAction => ({
  type: ActionTypes.API_CALL_SUCCESS,
});
