import LoggingService from '../ports/LoggingService';

export default function errorHandler(loggingService: LoggingService) {
  return (event: ErrorEvent) => {
    loggingService.log({
      message: 'Unexpected error',
      error: event.error,
      details: {
        filename: event.filename,
        colno: event.colno,
        lineno: event.lineno,
      },
      level: 'error',
      url: window.location.href,
    });
  };
}
